import { useEffect, useState } from "react";
import useTranslation from "next-translate/useTranslation";
import { imageCompressor } from "@/helpers";
import { Label } from ".";
import { useFormContext } from "react-hook-form";

import styles from "../atoms/styles/Upload.module.scss";
import { get, isEmpty, isString } from "lodash";
import classNames from "classnames";

type Props = {
    labelPath?: string,
    name: string,
    field?: {
        onChange?: (prop: any) => any,
    },
    required?: boolean
};

const Upload = ({
    labelPath = "forms:fields.coverPhoto",
    name,
    field: { onChange } = {},
    required = false
}: Props) => {
    const { t } = useTranslation();
    const { register, formState: { errors = {} } = {}, setValue, getValues } = useFormContext() || {};
    if (!register) {
        console.error(`Upload input ${name} outside context!`);
    }
    const isError = !!get(errors, name, false);
    const [uploadedFileUrl, setFileUrl] = useState<string>();

    const handleUpload = async (e: Event) => {
        //@ts-ignore
        const photoInput = e?.target?.files[0];
        console.warn("handleUpload", e, !photoInput);

        if (!e || !photoInput) return;

        const file = await imageCompressor(photoInput);

        const response = await fetch("/api/s3", {
            method: "POST",
            body: JSON.stringify({
                type: file?.type,
                name: file?.name
            })
        });

        const { url } = await response.json();
        const responsePut = await fetch(url, {
            method: "PUT",
            body: file,
            //@ts-ignore 
            headers: {
                "Content-type": file?.type
            }
        });
        const imageUrl = `${responsePut?.url.split("?")[0]}`;
        setFileUrl(imageUrl);
        !onChange ?
            setValue(name, imageUrl)
            :
            onChange({
                ...file,
                imageUrl
            });
    };

    useEffect(() => {
        const initImageUrl = getValues(name);

        if (!isEmpty(initImageUrl)) {
            console.log({ initImageUrl });

            // todo jak będzie możliwość więcej dodać zdjęć
            setFileUrl(
                isString(initImageUrl)
                    ? initImageUrl
                    : get(initImageUrl, '[0].url', ""));
        }
    }, []);

    return <div className={styles.wrapper}>
        <div className={styles.controller}>
            {labelPath && <Label
                isError={isError}
                name={name}
                required={required}
            >{t(labelPath)}</Label>}
            <div className={styles.btnWrapper}>
                <input
                    accept="image/*"
                    //@ts-ignore
                    onChange={handleUpload}
                    type="file"
                    className={styles.input}
                />
                <div
                    className={classNames(
                        styles.inputDesign,
                        {
                            [styles.withError]: isError
                        }
                    )}
                >
                    {uploadedFileUrl ?
                        t("common:photoAdded") :
                        t("forms:fields.clickToAddPhoto")}
                </div>
            </div>
        </div>
        <div className={styles.photoList}>
            {!!uploadedFileUrl?.length && <img
                className={styles.photo}
                src={uploadedFileUrl || ""} alt="" />}
        </div>
    </div>;
};

export default Upload;