
import useTranslation from "next-translate/useTranslation";
import { Header } from "./atoms";
import styles from "./styles/WebsiteIntro.module.scss";

const WebsiteIntro = () => {
    const { t } = useTranslation();

    return <div className={styles.websiteIntro}>
        <div className={styles.header}>
            <Header
                topText={t("common:areYouLookingFor")}
                variant="h3"
            >
                {t("common:findCarForYou")}
            </Header>
        </div>
    </div>;
};

export default WebsiteIntro;