import useTranslation from "next-translate/useTranslation";
import { useForm, FormProvider, get } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Input, Checkbox, AcceptCheckbox } from "./atoms";
import { ContactSchema } from "@/utils/validators/validators";
import { useContext, useState } from "react";
import { Message } from "semantic-ui-react";

import styles from "./atoms/styles/Form.module.scss";
import { useSlack } from "@/hooks/index";
import { AuthContext } from "@/context/authContext";

type Props = {
    isAcceptCheckbox?: boolean
}

const ContactForm = ({
    isAcceptCheckbox = false
}: Props) => {
    const [isSended, setIsSended] = useState(false);
    const [isFormDisabled, setIsFormDisabled] = useState<boolean>(isAcceptCheckbox);

    const { t } = useTranslation();
    const { send } = useSlack("Kontakt");
    const authContext = useContext(AuthContext);
    const loggedUserEmail = get(authContext, "attrInfo", [])
        .find(({ Name }: { Name: string }) => Name === "email")?.Value || "";

    const methods = useForm({
        resolver: zodResolver(ContactSchema),
        defaultValues: {
            email: loggedUserEmail,
            message: ""
        }
    });
    const { handleSubmit, reset, } = methods;

    const onSubmit = (data: any) => {
        const { email, message } = data;

        send({ owner: email, msg: message });

        setIsSended(true);
        onFormReset();
    };

    const onFormReset = () => {
        reset({
            email: "",
            message: "",
        });

        setTimeout(() => {
            setIsSended(false);
        }, 5000);
    };


    return <FormProvider {...methods} >
        <form
            onSubmit={handleSubmit(onSubmit)}
            className={styles.contactForm}
        >
            <h4>
                {t("common:contactInfo")}
            </h4>
            {isSended && <Message positive>
                <Message.Header>{t("common:sended")}!</Message.Header>
            </Message>}
            <Input
                name="email"
            />
            <Input
                name="message"
                inputType="textarea"
            />
            {
                isAcceptCheckbox && <>
                    <Checkbox
                        text={<AcceptCheckbox />}
                        checked={!isFormDisabled}
                        handleChange={() => {
                            setIsFormDisabled(!isFormDisabled);
                        }}
                    />
                    <br />
                </>
            }
            <br />
            <Button
                disabled={isFormDisabled}
            >
                {t("common:btn.send")}
            </Button>
        </form>
    </FormProvider>;
};

export default ContactForm;