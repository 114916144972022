import { useState } from "react";
import { GrClose } from "react-icons/gr";
import classNames from "classnames";
import { motion } from "framer-motion";

import styles from "./styles/SlideFrame.module.scss";

type TriggerProps = {
    onClick: () => void
};

type Props = {
    children: any,
    Trigger: (props: TriggerProps) => JSX.Element,
    title?: string
}

const SlideFrame = ({
    Trigger,
    children,
    title
}: Props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return <>
        <Trigger
            onClick={() => {
                setIsOpen(true);
            }}
        />
        <motion.div
            className={classNames(styles.slideFramePopup, {
                [styles.isOpen]: isOpen
            })}
            onPan={(_, { delta }) => {
                if (delta?.y > 0 && delta?.y > delta?.x) {
                    setIsOpen(false);
                }
            }}
        >
            <div className={styles.slideFramePopupClose}>
                <GrClose
                    onClick={() => {
                        setIsOpen(false);
                    }}
                />
            </div>
            {title && <div className={styles.title}>{title}</div>}
            <div className={styles.slideFramePopupChildren}>
                {children}
            </div>
        </motion.div>
    </>;
};

export default SlideFrame;