import { CompanyI } from "src/types/Company";
import { getCompanySlug } from "../getters";

const companyParser = (company: CompanyI) => {
    const url = getCompanySlug(company, true);
    const baseUrl = getCompanySlug(company, false);

    return {
        ...company,
        url,
        baseUrl
    };
};


export default companyParser;