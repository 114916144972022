
import classNames from "classnames";
import { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

import styles from "../styles/Accordion.module.scss";

type Props = {
    title: string,
    more: string,
    isAlwaysOpen?: boolean,
    isDefaultOpen?: boolean,
}

const Accordion = ({
    title,
    more,
    isAlwaysOpen = false,
    isDefaultOpen = false
}: Props) => {
    const [isOpen, setIsOpen] = useState<boolean>(isAlwaysOpen || isDefaultOpen);

    return <div className={
        classNames(styles.accordion, {
            [styles.isOpen]: isOpen,
            [styles.isAlwaysOpen]: isAlwaysOpen,
        })
    }>
        <div
            className={styles.accordionTitle}
            onClick={() => {
                !isAlwaysOpen && setIsOpen(!isOpen);
            }}
        >
            {title} {!isAlwaysOpen && ( !isOpen ? <IoIosArrowDown /> : <IoIosArrowUp />)}
        </div>
        <div className={styles.accordionMore}>
            {more}
        </div>
    </div>;
};

export default Accordion;