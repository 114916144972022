import { useEffect } from "react";
import { PRICE_LIST, COST_LIST, CAR, CURRENCY_ENUM } from "@/utils/enums";
import useTranslation from "next-translate/useTranslation";
import { useFormContext, useFieldArray } from "react-hook-form";
import { CarPriceRow, Input, Button } from ".";
import { IoIosAddCircleOutline } from "react-icons/io";

import styles from "./atoms/styles/Form.module.scss";
import { Header } from "../Layout";

const CarPriceListForm = () => {
    const { control, getValues,  setValue } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: CAR.PRICE_LIST, // unique name for your Field Array
    });
    const { t } = useTranslation();

    const addNextPriceRow = () => {
        const lastIndex = fields.length - 1;
        const lastRangeTopValue = getValues(`${CAR.PRICE_LIST}[${lastIndex}]`)?.rangeTop;

        append({
            cost: undefined,
            rangeTop: lastRangeTopValue + 3,
            rangeBottom: lastRangeTopValue,
        });
    };


    useEffect(() => {
        setValue(PRICE_LIST.CURRENCY, CURRENCY_ENUM.PLN);
        if (fields.length === 0) {
            append({
                cost: undefined,
                rangeTop: 3,
                rangeBottom: 1,
            });
        }
    }, []);

    return <div>
        <Header
            variant="h4"
            spaceBottom
            spaceTop
        >
            {t("common:money.cost")}
        </Header>
        <div className={styles.formRow}>
            <Input
                name={COST_LIST.BAIL_COST}
                type="number"
                currencyAnnotation
            />
            <Input
                name={COST_LIST.DISTANCE_LIMIT}
                type="number"
            />
            <Input
                name={COST_LIST.PRICE_ABOVE_LIMIT}
                type="number"
                step="0.01"
            />
        </div>
        <Header
            variant="h4"
            spaceBottom
            spaceTop
        >
            {t("common:money.rates")}
        </Header>
        {fields.map(
            ({ id, ...rest }, index) => <CarPriceRow
                key={id}
                index={index}
                remove={remove}
                {...rest}
            />
        )}
        <div className={styles.sectionGap} />
        <Button
            kind="link"
            type="button"
            onClick={() => {
                addNextPriceRow();
            }}>
            <IoIosAddCircleOutline /> {t("common:btn.addNextRange")}
        </Button>
    </div >;
};

export default CarPriceListForm;