import useTranslation from 'next-translate/useTranslation';
import classNames from "classnames";

import s from './styles/InfoBox.module.scss'


type Props = {
    label?: string,
    additionalLabel?: string,
    size?: 'normal' | 'big'
}

const InfoBox = ({
    label,
    additionalLabel,
    size = 'normal'
}: Props) => {
    const { t } = useTranslation();

    return <div className={classNames(s.emptyList, s[size])}>
        {label || t("common:notFound")}
        {
            additionalLabel && <div className={s.additionalLabel}>
                {additionalLabel}
            </div>
        }
    </div>;
}

export default InfoBox