import { CompanyGeoAddressI } from "@/types";
import config from "../../../config"; // testy zle dzialaja z tym

const {
    phonePrefixes = []
} = config;

export const getStreetAddress = (address: CompanyGeoAddressI) => {
    const {
        street,
        apartmentNumber,
        numberOfTheBuilding,
    } = address || {};

    return `${street} ${numberOfTheBuilding}${apartmentNumber ? "/" + apartmentNumber : ""}`.trim();
};


const phonePrefiexesRemove = (phone: string) => {
    const phoneNumber = phone.replace(/\+/g, "");

    return phonePrefixes.reduce((
        acc, prefix
    ) => {
        if (phoneNumber.startsWith(prefix)) return phoneNumber.slice(prefix.length);

        return acc;
    }, "") || phone;
};

export const getPhoneNumber = (phone: string | number, withSpaces = false) => {
    const phoneNumber: string = (phone + "").replace(/\s/g, "");
    const parsedPhone = phonePrefiexesRemove(phoneNumber);

    if (withSpaces) {
        //@ts-ignore
        const spaces = [...parsedPhone]
            .reduce((acc, str, index) =>
                `${acc}${index % 3 === 0 ? " " + str : str}`, "");

        return spaces.trim();
    }

    return parsedPhone;
};