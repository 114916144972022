import { ErrorMessage } from "@hookform/error-message";
import ReactTooltip from "react-tooltip";
import { get, isString } from "lodash";
import useTranslation from "next-translate/useTranslation";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import Select, { SingleValue } from "react-select";
import CreatableSelect from "react-select/creatable";

import { Label } from "./";

import styles from "./styles/Inputs.module.scss";
import classNames from "classnames";

type Options = {
    value: string | number,
    label: string,
};

type Props = {
    options: Options[],
    label?: string,
    isDisabled?: boolean,
    placeholder?: string,
    name: string,
    required?: boolean,
    value?: any,
    withLabel?: boolean,
    Icon?: JSX.Element,
    isCreatable?: boolean
};

const MySelect = ({
    options,
    label,
    isDisabled,
    placeholder,
    required,
    name,
    withLabel = true,
    Icon,
    isCreatable = false
}: Props) => {
    const [inputState, setInputState] = useState<Options | null>();
    const [isInputOpened, setIsInputOpened] = useState<boolean>(false);
    const { t } = useTranslation();
    const { register, formState: { errors }, getValues, setValue } = useFormContext();
    if (!register) {
        console.error(`Input ${name} outside context!`);
    }

    const getLabel = (label = "") => t(`forms:fields.${label}`, {}, {
        fallback: `forms:fields.${label.replace(/[0-9]./g, "")}`
    });

    const isError = !!get(errors, name, false);
    const defaultLabel = withLabel ? `${t("common:select")}...` : <div className={styles.selectLabel}>
        {Icon && Icon}
        {label || getLabel(name)}
    </div>;

    const setDefaultValue = () => {
        const defaultValue = getValues(name);
        const defaultSelected: Options | undefined = options.find(({ value }) => value === defaultValue);

        if (defaultSelected && isString(defaultValue)) {
            handleInputChange(defaultSelected);
        }
    };

    const handleInputChange = (value: SingleValue<Options>) => {
        setValue(name, value);
        setInputState(value);
    };

    useEffect(() => {
        setDefaultValue();
    }, []);

    const Component = isCreatable ? CreatableSelect : Select;

    return <div className={styles.selectWrapper}>
        <a data-tip data-for={name}>
            {
                withLabel && <Label
                    name={name}
                    isError={isError}
                    required={required}
                >
                    {label || getLabel(name) || defaultLabel}
                </Label>
            }
            <Component
                className={classNames(
                    styles.select,
                    {
                        [styles.withError]: isError
                    }
                )}
                isDisabled={isDisabled}
                options={options}
                value={inputState}
                placeholder={placeholder ? placeholder : defaultLabel}
                onChange={handleInputChange}
                onMenuOpen={() => setIsInputOpened(true)}
                onMenuClose={() => setIsInputOpened(false)}
                //@ts-ignore
                formatCreateLabel={val => `${t("forms:fields.createSelect")} ${val}`}
            />
        </a>
        {isError && !isInputOpened && <ReactTooltip
            id={name}
            type='error'
            effect='solid'
            place="bottom"
        >
            <ErrorMessage
                errors={errors}
                name={name}
                render={({ messages }) => {
                    return messages &&
                        Object.entries(messages).map(([type, message]) => (
                            <div key={type}>{isString(message) ? t(message, {}, {
                                fallback: t(`zod:errors.${message}`)
                            }) : "Błąd"}</div>
                        ));
                }}
            />
        </ReactTooltip>
        }
    </div>;
};

export default MySelect;