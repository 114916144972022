import Link from "next/link";
import useTranslation from "next-translate/useTranslation";
import classNames from "classnames";
import { AuthIsSignedIn, AuthIsNotSignedIn, AuthContext } from "@/context/authContext";
import { ToggleBtn, Logo } from ".";
import styles from "./styles/Navbar.module.scss";
import { Button } from "../Form";
import { useContext } from "react";
import { useRouter } from "next/router";
import config from "@/config";


const {
    flags: {
        isBlog = false
    } = {}
} = config;

type NavbarProps = {
    isScrollOnTop: boolean,
    isOpen: boolean,
    setIsOpen: any,
}

const Navbar = ({
    isScrollOnTop,
    isOpen,
    setIsOpen
}: NavbarProps) => {
    const { t } = useTranslation();
    const authContext = useContext(AuthContext);
    const router = useRouter();

    return <div className={classNames(styles.navbar, {
        [styles.onTop]: isScrollOnTop,
        [styles.onScrolled]: !isScrollOnTop,
    })}>
        <Logo />
        <AuthIsSignedIn>
            <div className={classNames(styles.navbarButtons)}>
                {isBlog && <a
                    href="https://www.funcary.pl/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {
                        t("common:blog")
                    }
                </a>}
                <Link href="/auth">
                    <Button>
                        {t("common:btn.myAccount")}
                    </Button>
                </Link>
                <Button
                    onClick={() => {
                        authContext.signOut();
                        router.reload();
                    }}
                >
                    {t("common:btn.logout")}
                </Button>
            </div>

        </AuthIsSignedIn>
        <AuthIsNotSignedIn>
            <div className={classNames(styles.navbarButtons)}>
                <Link href="/kontakt">
                    {
                        t("common:contactRent")
                    }
                </Link>
                {isBlog && <a
                    href="https://www.funcary.pl/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {
                        t("common:blog")
                    }
                </a>}
                <Link href="/auth/register">
                    <Button>
                        {t("common:btn.registerAccount")}
                    </Button>
                </Link>
                <Link href="/auth/login">
                    <Button>
                        {t("common:btn.login")}
                    </Button>
                </Link>
            </div>
        </AuthIsNotSignedIn>
        <div className={styles.mobile}>
            <ToggleBtn
                isOpen={isOpen}
                setIsOpen={setIsOpen}
            />
        </div>
    </div>;
};

export default Navbar;