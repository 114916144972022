import { CarI, CompanyI } from "@/types";
import slugify from "slugify";

export const getCarSlug = (car: Partial<CarI>): string | null => {
    const {
        brand: {
            name = null,
        } = {},
        model = null,
        id = null
    } = car;
    if (id === null) return null;

    const slug = car?.slug
        ? car?.slug
        : `${name}-${model}`;

    const slugBase = slugify(`${slug}-${car?.id}`, {
        lower: true,
        strict: true,
    });

    return slugBase;
};

export const getCarIdFromSlug = (slug: string): number => {
    const id = slug.split("-").pop() || 0;
    //todo może lepiej zabezpieczyć czy to prawdziwe id?
    return +id;
};

export const getCompanySlug = (company: CompanyI, asUrl = false): string => {
    const slug = `${company?.name}-${company?.id}`;
    const slugBase = slugify(slug, {
        lower: true,
        strict: true,
    });

    return asUrl ? `/company/${slugBase}` : slugBase;
};

export const getIdFromSlug = (slug: string): number => { //getCarIdFromSlug
    const id = slug.split("-").pop() || 0;
    //todo może lepiej zabezpieczyć czy to prawdziwe id?
    return +id;
};