import { Header } from "@/c/Layout";
import { isEmpty, isString } from "lodash";
import useTranslation from "next-translate/useTranslation";
import { useState } from "react";
import { Button, Checkbox } from ".";
import styles from "../atoms/styles/Form.module.scss";

type Props = {
    children: any,
    onSubmit: any,
    footer?: JSX.Element,
    title?: string,
    buttonLabel?: string,
    errors?: string[] | string,
    confirmChecbox?: JSX.Element
};

const FormWrapper = ({
    children,
    onSubmit,
    title,
    footer,
    buttonLabel = "save",
    errors,
    confirmChecbox = undefined
}: Props) => {
    const { t } = useTranslation();
    const [isFormDisabled, setIsFormDisabled] = useState<boolean>(!!confirmChecbox);

    return <form
        action=""
        onSubmit={onSubmit}
        className={styles.form}>
        {
            title && <Header
                variant='h2'
            >
                {title}
            </Header>
        }
        <ErrorComponent
            errors={errors}
        />
        <div>
            {children}
        </div>
        {
            footer ? footer : <div className={styles.formFooter}>
                {confirmChecbox && <><Checkbox
                    checked={!isFormDisabled}
                    handleChange={() => {
                        setIsFormDisabled(!isFormDisabled);
                    }}
                    text={confirmChecbox}
                />
                <br />
                </>
                }
                <div className={styles.formFooterButton}>
                    <Button
                        disabled={isFormDisabled}
                    >{t(`common:btn.${buttonLabel}`)}</Button>
                </div>
            </div>
        }
    </form>;
};

type ErrorProps = {
    errors?: string[] | string
}
const ErrorComponent = ({ errors = [] }: ErrorProps) => {
    if (isEmpty(errors)) return <></>;

    if (isString(errors)) {
        return <div className={styles.errorBox}>
            {errors}
        </div>;
    }

    return (errors || []).some(item => !isEmpty(item)) ? <div className={styles.errorBox}>
        {
            errors?.map(text => <p key={text}>{text}</p>)
        }
    </div> : <></>;
};

export default FormWrapper;