import { COMPANY_CONTACT } from "@/enum";
import { getPhoneNumber, getSocialsArray, parseUrlToDisplay } from "@/getters";
import useTranslation from "next-translate/useTranslation";
import { useEffect } from "react";
import {  useFormContext } from "react-hook-form";
import { SocialsU } from "src/types/Company";
import { Input } from "./";
import styles from "../Form/atoms/styles/Form.module.scss";
import { Header } from "../Layout";

type Props = {
    isError?: boolean
}
const CompanyContactForm = ({
    isError = false
}: Props) => {
    const { t } = useTranslation();

    return <div>
        <Header
            variant="h4"
            spaceTop
        >
            {t("common:contact")}
        </Header>
        {isError && <div className={styles.errorBox}>
            {t("zod:errors.contactNotEmpty")}
        </div>}
        <div className={styles.formRow}>
            <Input
                name={COMPANY_CONTACT.email}
            />
            <Input
                name={COMPANY_CONTACT.phone}
                parser={x => getPhoneNumber(x, true)}
            />
            <Input
                name={COMPANY_CONTACT.websiteUrl}
                parser={parseUrlToDisplay}
            />
        </div>
        <Header
            variant="h4"
            spaceTop
            spaceBottom
        >
            {t("common:socialMedia")}
        </Header>
        <div className={styles.formRow}>
            {
                getSocialsArray()
                    .map(
                        (type, index) =>
                            <SocialInputGroup
                                key={index}
                                index={index}
                                type={type} />
                    )
            }
        </div>
    </div>;
};

export default CompanyContactForm;


type SocialInputProps = {
    index: number,
    type: SocialsU
}
const SocialInputGroup = ({ index = 0, type }: SocialInputProps) => {
    const { setValue } = useFormContext();
    const { t } = useTranslation();

    useEffect(() => {
        setValue(`contact.urls[${index}].type`, type);
    });

    return <div>
        <div className={styles.socialHeader}>
            {t(`forms:fields.socials.${type}.label`)}
        </div>
        <Input
            label={t(`forms:fields.socials.${type}.name`)}
            placeholder={t(`forms:fields.socials.${type}.placeholder`)}
            name={`contact.urls.${index}.name`}
        />
        <Input
            label={t(`forms:fields.socials.${type}.url`)}
            name={`contact.urls.${index}.url`}
            placeholder={t(`forms:fields.socials.${type}.url`)}
            parser={parseUrlToDisplay}
        />
    </div>;
};