
const { NEXT_PUBLIC_IS_BLOG } = process.env;

const config = {
    websiteName: 'Funcary',
    suggestApi: "https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest",
    findAddressCandidates: "https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates",
    socials: {
        instagram: "https://www.instagram.com/funcarypl/"
    },
    urls: {
        user: {
            register: "/user/register",
            addCompany: "/user/add-company",
            getCompany: "user/company",
            // getCompanies: "user/companies",
            getProfile: "/user/profile",
            updateCompany: "/user/company/update",
            getCompanies: "/user/companies"
        },
        car: {
            getList: "/car/list",
            addCar: "/car/add-car",
            updateCar: "car/update-car",
            getBrands: "/car/brands"
        }
    },
    phonePrefixes: ["48"],
    brandList: [
        "Abarth", "Aixam", "Alfa Romeo", "Ariel", "Aston Martin", "Audi", "Bentley", "BMW", "Bugatti", "Buick", "Cadillac", "Caterham", "Chevrolet", "Chrysler", "Citroën", "Corvette", "Dacia", "Daihatsu", "Dodge", "Ferrari", "Fiat", "Ford", "General Motors", "GMC", "Great Wall", "Gumpert", "Hennessey logo", "Holden", "Honda", "Hummer", "Hyundai", "Infiniti", "Isuzu", "Jaguar", "Jeep", "Joss", "Kia", "Koenigsegg", "Lada", "Lamborghini", "Lancia", "Land Rover", "Lexus", "Lincoln", "Lotus", "Maserati", "Maybach", "Mazda", "Mclaren", "Mercedes", "Mini", "Mitsubishi", "Nissan", "Noble", "Opel", "Pagani", "Peugeot", "Porsche", "Proton", "Renault", "Rolls Royce", "Saab", "Scion", "Seat", "Skoda", "Smart", "Ssangyong", "Subaru", "Suzuki", "Tata", "Tesla", "Toyota", "Volkswagen", "Volvo"
    ],
    currency: {
        PLN: "zł"
    },
    flags: {
        isBlog: (NEXT_PUBLIC_IS_BLOG === "true") || false
    }
}

export default config;