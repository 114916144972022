import Head from "next/head";
import { BrandT, CarsAndCostsT, CarWithCompany, CompanyI, FilterCarProps } from "@/types";
import { useRouter } from "next/router";
import { get, truncate, merge } from "lodash";
import { DataContext } from "@/context/dataContext";
import { useContext } from "react";
import { getBrandNameById } from "@/utils/getters";

//todo translate jak będzie trzeba
const defaultValues = {
    title: "funcary - Znajdź i wynajmij dla siebie najlepszy samochód",
    description: "Znajdź najlepsze ceny w wielu lokalizacjach i wybierz spośród szerokiej gamy samochodów sportowych, luksusowych, osobowych czy busów. Mamy takie marki jak Cupra, Audi, VW, Mercedes, BMW czy Dodge",
    url: "https://www.funcary.pl",
    image: "https://www.funcary.pl/static/coverPhotoSeo.png",
};

type CarProps = {
    page: "CAR",
    data: CarWithCompany
}

type CompanyProps = {
    page: "COMPANY",
    data: CompanyI
}

type SearchProps = {
    page: "SEARCH",
    data?: {
        cars: CarsAndCostsT[],
        filter: FilterCarProps
    }
}

type PostSeoDataProps = {
    title: string
    intro: string
    photoUrl: string
}
type PostProps = {
    page: "POST",
    data: PostSeoDataProps
}

type PagesProps = {
    page: "OTHER" | "CONTACT" | "BLOG",
    data?: {} // eslint-disable-line
}

type MainPageProps = {
    page: "MAIN",
    data?: {} // eslint-disable-line
}

type Props = CarProps | CompanyProps | PagesProps | MainPageProps | SearchProps | PostProps;

type SeoProps = {
    title: string,
    description: string,
    url: string,
    image: string,
}

const getCarPageData = (data: CarWithCompany) => {
    const {
        brand: {
            name: brandName
        } = {},
        parameters: {
            engine
        } = {},
        company: {
            name: companyName
        } = {},
        model,
        originalDesc: carDescription
    } = data;
    const carBasicInfo = `${brandName} ${model} ${engine}`;
    const title = `${companyName} - ${carBasicInfo}`;
    const description = `${truncate(carDescription, {
        length: 180
    })}`;
    const coverPhoto: string | null = get(data, "photos[0].url", null);

    return {
        title,
        description,
        image: coverPhoto,
    };
};

const getCompanyPageData = (data: CompanyI) => {
    const {
        name: companyName,
        coverPhoto: companyLogo = null,
        originalDesc: companyDescription,
        address: {
            city = "",
        } = {},
    } = data;

    const title = `${companyName} - ${city}`;
    const description = `${truncate(companyDescription, {
        length: 180
    })}`;

    return {
        title,
        description,
        image: companyLogo,
    };
};
const getSearchPageData = ({
    cars, filter, brands
}: {
    cars: CarsAndCostsT[],
    filter: FilterCarProps,
    brands: BrandT[]
}) => {
    const {
        car: {
            brandName: brandNameFilter,
            brandId = null
        } = {}
    } = filter;

    const brandName = brandNameFilter || getBrandNameById(brandId, brands);
    const carNames = cars.map(({ car }) => car?.model)?.join(",");

    if (brandName && carNames) {
        return {
            title: `Funcary - sprawdź ${brandName} i inne marki`,
            description: `Porównaj oferty ${brandName} takich jak: ${carNames}`,
        };
    }

    return {
        title: "Funcary - sprawdź jakie samochody oferują wypożyczalnie",
        description: "Porównaj oferty wypożyczalni i wybierz auto dla siebie",
    };
};

const getPostPageData = ({
    title, intro, photoUrl
}: PostSeoDataProps) => {

    return {
        title,
        description: `${truncate(intro, {
            length: 180
        })}`,
        image: photoUrl
    }
}

const prepareSeo = (page: string, data: any, brands: BrandT[]) => {
    let seo = {};
    switch (page) {
        case "CAR":
            seo = getCarPageData(data);
            break;
        case "COMPANY":
            seo = getCompanyPageData(data);
            break;
        case "SEARCH":
            seo = getSearchPageData({ ...data, brands });
            break;
        case "POST":
            seo = getPostPageData({ ...data });
            break;
        case "BLOG":
            seo = {
                title: "Funcary - blog o motoryzacji",
                description: "Blog o motoryzacji, samochodach, wynajmie, eventach oraz nowościach w świecie samochodów"
            }
            break;
        default:
            seo = defaultValues;
    }

    return seo;
};

const NextHead = ({
    page,
    data = {}
}: Props) => {
    const router = useRouter();
    const { brands } = useContext(DataContext);

    const preparedSeo = prepareSeo(page, data, brands);
    const seoData: SeoProps = merge(defaultValues, preparedSeo);

    const url = `www.funcary.pl${router.asPath}`;

    return <Head>
        <title>{seoData.title}</title>
        <meta property="og:title" content={seoData.title} />
        <meta property="og:description" content={seoData.description} />
        <meta name="description" content={seoData.description} key="desc" />
        <meta property="og:url" content={url} />
        <meta property="og:image" content={seoData.image} />
        <meta property="og:image:secure_url" content={seoData.image} />
    </Head>;
};

export default NextHead;