
import classNames from "classnames";
import styles from "../styles/Section.module.scss";


type Props = {
    children: React.ReactNode,
    withPaddingsY?: boolean,
    isNarrow?: boolean,
    color?: "white" | "grey" | "primary" | "none"
}

const Section = ({
    children,
    color = "none",
    withPaddingsY = false,
    isNarrow = true
}: Props) => {

    return <section className={classNames(
        styles.section, styles[color], {
            [styles.paddingY]: withPaddingsY,
            [styles.isNarrow]: isNarrow
        }
    )}>
        {children}
    </section>;
};

export default Section;