import { serverApi } from "@/utils/api";
import config from "@/config";
import { createContext, useState } from "react";
import { noop } from "lodash";
import { BrandT } from "@/types";


const defaultState: StateT = {
    getBrands: async () => [],
    brands: []
};

export const DataContext = createContext(defaultState);

type Props = {
    children?: React.ReactNode
}

type StateT = {
    getBrands: () => Promise<BrandT[] | []>;
    brands: BrandT[]
}


const { urls: { car: { getBrands: getBrandsUrl = "" } = {} } } = config;


const DataProvider = ({ children }: Props) => {
    const [brands, setBrands] = useState<BrandT[]>([]);

    const getBrands = async () => {
        if (brands.length) return brands;
        try {
            const {
                data: {
                    list = []
                } = {}
            } = await serverApi.get(getBrandsUrl);

            setBrands(list);
            return list;
        } catch (e) {
            console.error("Fetch brands", e);

            return [];
        }
    };

    const state: StateT = {
        getBrands,
        brands
    };

    return <DataContext.Provider value={state}>
        {children}
    </DataContext.Provider>;
};

export default DataProvider;