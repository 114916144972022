const AcceptCheckbox = () => {

    return <div>Akceptuję <a
        href='/rules'
        target="_blank"
    >
        regulamin
    </a> i <a
        href='/policy'
        target="_blank"
    >politykę prywatności</a>
    </div>;
};

export default AcceptCheckbox;