import Link from "next/link";
import { MdManageAccounts, MdOutlineLogout } from "react-icons/md";
import OwnSocials from "./OwnSocials";
import { AuthContext, AuthIsSignedIn } from "@/context/authContext";
import { useContext } from "react";
import useTranslation from "next-translate/useTranslation";
import classNames from "classnames";

import styles from "../styles/SideDrawer.module.scss";

type Props = {
    isOpen: boolean,
    setIsOpen: any,
    withLabel?: boolean
}

const SideDrawerFooter = ({
    isOpen,
    setIsOpen,
    withLabel = true
}: Props) => {
    const { t } = useTranslation();
    const authContext = useContext(AuthContext);

    return <div className={styles.sideDrawerFooter}>
        <OwnSocials />
        <AuthIsSignedIn>
            <div className={classNames(styles.accountCtrl, styles.drawerFooter)}>
                {withLabel && <div className={styles.drawerFooterTitle}>
                    {t("common:accountLabel")}
                </div>
                }
                <div className={styles.drawerFooterList}>
                    <Link
                        href="/auth"
                    >
                        <MdManageAccounts
                            onClick={() => {
                                setIsOpen(!isOpen);
                            }}
                        />
                    </Link>
                    <MdOutlineLogout
                        onClick={() => { authContext.signOut(); }}
                    />
                </div>
            </div>
        </AuthIsSignedIn>
    </div>;
};

export default SideDrawerFooter;