import { isEmpty } from "lodash";
import * as z from "zod";
import { ZodTypeAny } from "zod";
import {
    BODY_TYPE_ENUM,
    COLOR_ENUM,
    CURRENCY_ENUM,
    DRIVE_ENUM,
    FUEL_ENUM,
    STATUS_ENUM,
    TRANSMISSION_ENUM,
    SOCIAL_TYPE_ENUM
} from "../enums";
// import priceRowsValidation from "./priceRowsValidation";

const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&\.]{8,}$/g;
const urlRegex = /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/g;
const urlValidation = z.string().regex(urlRegex);
const requiredString = z.string().trim().min(1, { message: "required" });

// LOL! zod problem in this version of resolver
export const numericString = (schema: ZodTypeAny) => z.preprocess((a) => {
    if (typeof a === "string") {
        return parseInt(a, 10);
    } else if (typeof a === "number") {
        return a;
    } else {
        return undefined;
    }
}, schema);

const optionalNumber = numericString(z.number().optional().or(z.nan()));

export const EmailSchema = z.object({
    email: z.string().email()
});

export const ContactSchema = z.object({
    email: z.string().email(),
    message: z.string().min(10),
});

export const ResetCodeSchema = z.object({
    email: z.string().email(),
    code: z.string(),
    password: z.string().regex(passRegex, "password"),
});

export const RegisterSchema = z.object({
    email: z.string().email(),
    password: z.string().regex(passRegex, "password"),
    address: z.object({
        value: z.string(),
        label: z.string(),
    }).optional(),
    phone: z.string().length(9).or(z.string().length(12)),
});

export const LoginSchema = z.object({
    email: z.string().email(),
    password: z.string()
});

export const UrlTypeSchema = z.nativeEnum(SOCIAL_TYPE_ENUM);

const UrlSchema = z.object({
    type: UrlTypeSchema,
    name: z.string(),
    url: urlValidation.or(z.literal("")),
});

const UrlSchemaHack = z.object({
    type: UrlTypeSchema,
    name: requiredString,
    url: urlValidation,
});



const CompanyAddressSchema1 = z.object({
    latitude: numericString(z.number()),
    longitude: numericString(z.number()),
    street: z.string().optional(),
    numberOfTheBuilding: z.string().optional(),
    postalCode: z.string().optional(),
});

const CompanyAddressSchema2 = z.object({
    street: requiredString,
    numberOfTheBuilding: requiredString,
    postalCode: requiredString,
    latitude: optionalNumber,
    longitude: optionalNumber,
});

const CompanyAddressSchemaBase = z.object({
    city: requiredString,
    region: z.string().optional(),
    subregion: z.string().optional(),
    country: z.string(),
    formattedAddress: z.string().optional(),
    apartmentNumber: z.string().optional(),
});

export const CompanyAddressSchema = CompanyAddressSchemaBase
    .merge(CompanyAddressSchema1)
    .or(CompanyAddressSchemaBase
        .merge(CompanyAddressSchema2)
    );

export const AddCompanySchema = z.object({
    name: z.string().min(3),
    description: z.string().optional(),
    coverPhoto: z.string().optional(),
    contact: z.object({
        websiteUrl: urlValidation.or(z.literal("")),
        phone: z.string().optional().transform((phone) => {
            const phoneNumber: string = (phone + "").replace(/\s/g, "");

            return phoneNumber;
        }),
        email: z.string().email().optional().or(z.literal("")),
        urls: z.array(UrlSchema.partial().superRefine(({
            name, type, url
        }, ctx) => {
            if (isEmpty(url) && isEmpty(name)) return true;

            const result = UrlSchemaHack.safeParse({
                type, name, url
            });

            if (!result.success) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: "Invalid url",
                    path: ["url", "custom"]
                });
                return false;
            } else {
                return result.data;
            }
        })).optional()
    }).optional().refine(schema => schema?.websiteUrl || schema?.phone || schema?.email, {
        message: "zod:errors.contactNotEmpty"
    }),
    companyAddress: CompanyAddressSchema
});


// CAR
const driveSchema = z.nativeEnum(DRIVE_ENUM);
const colorSchema = z.nativeEnum(COLOR_ENUM);
const transmissionSchema = z.nativeEnum(TRANSMISSION_ENUM);
const fuelSchema = z.nativeEnum(FUEL_ENUM);
const bodyTypeSchema = z.nativeEnum(BODY_TYPE_ENUM);
const statusSchema = z.nativeEnum(STATUS_ENUM);

const CarParametersSchema = z.object({
    engine: z.string().optional(),
    transmission: z.object({
        value: transmissionSchema,
        label: z.string()
    }).optional().or(z.null()),
    fuel: z.object({
        value: fuelSchema,
        label: z.string()
    }).optional().or(z.null()),
    drive: z.object({
        value: driveSchema,
        label: z.string(),
    }).optional().or(z.null()),
    color: z.object({
        value: colorSchema,
        label: z.string()
    }).optional().or(z.null()),
    body: z.object({
        value: bodyTypeSchema,
        label: z.string()
    }).optional().or(z.null()),
    horsePower: optionalNumber,
    seats: optionalNumber,
    acceleration: optionalNumber,
    productionYear: optionalNumber,
    vmax: optionalNumber,
    consumption: optionalNumber,
});

export const CurrencySchema = z.nativeEnum(CURRENCY_ENUM);

export const AdditionalCostSchema = z.object({
    bailCost: optionalNumber,
    distanceLimit: optionalNumber,
    priceAboveLimit: optionalNumber,
});

const PriceListSchema = z.object({
    cost: numericString(z.number()),
    rangeBottom: numericString(z.number()),
    rangeTop: numericString(z.number()),
});

const CarAddSchema = z.object({
    parameters: CarParametersSchema,
    photos: requiredString,
    description: z.string().optional(),
    status: z.object({
        value: statusSchema,
        label: z.string()
    }).optional(),
    model: requiredString,
    brand: z.object({
        value: z.number().or(requiredString),
        label: requiredString
    }),
    version: z.string().optional(),
});

const CarUpdateSchema = z.object({
    parameters: CarParametersSchema,
    photos: requiredString,
    description: z.string().optional(),
    status: z.object({
        value: statusSchema,
        label: z.string()
    }).optional(),
    model: requiredString,
    version: z.string().optional(),
});

const CostsSchema = z.object({
    priceList: z.array(PriceListSchema),
    additional: AdditionalCostSchema,
    currency: CurrencySchema.optional(),
}).optional();

export const CarSchemaAdd = z.object({
    car: CarAddSchema,
    costs: CostsSchema
});

export const CarSchemaUpdate = z.object({
    car: CarUpdateSchema,
    costs: CostsSchema
});
