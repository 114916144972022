export enum COMPANY {
    name = "name",
    description = "description",
    coverPhoto = "coverPhoto",
}

export enum COMPANY_CONTACT {
    websiteUrl = "contact.websiteUrl",
    phone = "contact.phone",
    email = "contact.email"
}

export enum COMPANY_ADDRESS {
    country = "companyAddress.country",
    city = "companyAddress.city",
    street = "companyAddress.street",
    numberOfTheBuilding = "companyAddress.numberOfTheBuilding",
    postalCode = "companyAddress.postalCode",
    apartmentNumber = "companyAddress.apartmentNumber",
}

export const SOCIAL_TYPE_ENUM = {
    FACEBOOK: "FACEBOOK",
    TIKTOK: "TIKTOK",
    INSTAGRAM: "INSTAGRAM",
    TWITTER: "TWITTER",
} as const;

export const LOGIN_STEP = {
    CODE: "passwordChanged",
    REGISTER : "afterRegister"
} as const;