import { isString } from "lodash";
import useTranslation from "next-translate/useTranslation";

type Props = {
    err: {
        input: string,
        error: {
            text: string,
            details: any
        }
    }
}
const ErrorItem = ({ err }: Props) => {
    const { t } = useTranslation();
    const {
        input,
        error: {
            text,
            details
        } = {}
    } = err;

    const parsedDetails = Object.entries(details).reduce((acc, [key, text]) => {
        const isToParse = isString(text) && text.startsWith("$t(");
        const newText = isToParse ? t(text.slice(3, -1)) : text;

        return {
            ...acc,
            [key]: newText
        };
    }, {});

    return <div>
        <div>{t(`forms:fields.${input}`)}</div>
        <div>{text && t(text, parsedDetails)}</div>
        <br />
    </div>;
};

export default ErrorItem;