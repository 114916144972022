import Link from "next/link";
import styles from "../styles/MobileMenuItem.module.scss";

type Props = {
    children?: string,
    href: string
    label: string
}

const MobileMenuItem = ({
    children,
    href,
    label
}: Props) => {

    return <div className={styles.mobileMenuItem}>
        <Link href={href}>
            {children || label}
        </Link>
    </div>;
};

export default MobileMenuItem;