import { IoCloseCircleOutline } from "react-icons/io5";

import styles from "./styles/Button.module.scss";


const CloseButton = (props: any) => {

    return <button
        className={styles.closeBtn}
        type="button"
        {...props}
    >
        <IoCloseCircleOutline />
    </button>;
};

export default CloseButton;