import { merge } from "lodash";
import { CompanyAddressGeo } from "@/types";

const addressSchema = {
    country: "",
    city: "",
    street: "",
    numberOfTheBuilding: "",
    postalCode: "",
    apartmentNumber: "",
    formattedAddress: "",
    region: "",
    subregion: "",
    latitude: null,
    longitude: null,
};

const companyAddressParser = (apiAddress: any):CompanyAddressGeo => {
    const {
        address: formattedAddress,
        location: {
            x: longitude,
            y: latitude
        },
        attributes : {
            City: city,
            Subregion: subregion,
            Region: region,
            Country: country,
            Postal: postalCode,
            StName: street,
            AddBldg: apartmentNumber,
            AddNum: numberOfTheBuilding
        }
    } = apiAddress;

    const address = {
        formattedAddress,
        latitude,
        longitude,
        city,
        subregion,
        region,
        country,
        postalCode,
        street,
        numberOfTheBuilding,
        apartmentNumber,
    };

    return merge(addressSchema, address);


};

export default companyAddressParser;