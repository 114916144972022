import axios from "axios";
import classNames from "classnames";
import { debounce, get, noop } from "lodash";
import { useFormContext } from "react-hook-form";
import useTranslation from "next-translate/useTranslation";
import AsyncSelect from "react-select/async";
import { getPlaceCoords } from "@/utils/getters";
import config from "@/config";
import { Label } from "./index";

import styles from "./styles/Inputs.module.scss";

type suggestionsT = {
    text?: string,
    label?: string,
    magicKey: string,
    isCollection: boolean,
    id?: number
}

type optionT = {
    label: string,
    magicKey: string,
    value: string,
    id: number | null
}

type Props = {
    name: string,
    label?: string,
    isDisabled?: boolean,
    placeholder?: string,
    required?: boolean,
    value?: any,
    withLabel?: boolean,
    Icon?: JSX.Element,
    isCreatable?: boolean,
    type?: "city" | "companyAddress",
    handleAddressChange?: any,
    isErrorAddress?: boolean,
    defaultInputValue?: string
};


const SelectLocation = ({
    withLabel = true,
    name,
    label,
    required,
    type = "city",
    handleAddressChange = noop,
    placeholder = "",
    Icon,
    isErrorAddress,
    defaultInputValue = ""
}: Props) => {
    const { register, formState: { errors }, setValue } = useFormContext();
    const { t } = useTranslation();
    const isError: boolean = !!get(errors, name, false) || !!isErrorAddress;

    const getLabel = (label = "") => t(`forms:fields.${label}`, {}, {
        fallback: `forms:fields.${label.replace(/[0-9]./g, "")}`
    });

    const getPlaceholder = ({
        placeholder,
        Icon
    }: {
        placeholder: string,
        Icon?: JSX.Element
    }) => {
        if (placeholder) {
            return <div className={styles.selectLabel}>
                {Icon && Icon}
                {placeholder || getLabel(name) || t("forms:placeholder.address")}
            </div>;
        }
        return ''
    };

    const defaultLabel = withLabel ? `${t("common:select")}...` : <div className={styles.selectLabel}>
        {Icon && Icon}
        {label || getLabel(name)}
    </div>;

    if (!register) {
        console.error(`Input ${name} outside context!`);
    }


    const prepareOptions = (searchResults: suggestionsT[] = []): optionT[] => {

        return searchResults.map(({ text, magicKey, id, label }) => ({
            label: text || label || "",
            magicKey,
            value: text || label || "",
            id: id || null
        }));
    };

    const fetchSuggestions = async (val: string, fn: any) => {
        if (val.length < 3) return fn([]);

        try {
            const { data } = await axios.get(`${config.suggestApi}?text=${val}&countryCode=PL&f=json&token=${process.env.NEXT_PUBLIC_ARCGIS_APITOKEN}`);
            const parsedOptions = prepareOptions(data?.suggestions);

            return fn(parsedOptions);
        } catch (e) {
            console.error("Suggest api", e);
            return fn([]);
        }
    };

    const fetchSuggestionsDebounced = debounce((val, fn) => { fetchSuggestions(val, fn); }, 500);

    const onAddressChange = async (inputVal: any) => {
        try {
            const fetchedDetails = await getPlaceCoords(inputVal);

            if (type === "city") {
                setValue(name, fetchedDetails?.city);
                setValue("placeDetails", fetchedDetails);
            }

            if (type === "companyAddress") {
                handleAddressChange(fetchedDetails);
            }
        } catch (e) {
            console.error("SelectLocation change ", e);
        }
    };

    return <div className={styles.selectWrapper}>
        {
            withLabel && <Label
                name={name}
                isError={isError}
                required={required}
            >
                {label || getLabel(name) || defaultLabel}
            </Label>
        }
        <AsyncSelect
            className={classNames(styles.select,
                { [styles.isSelectError]: isError }
            )}
            defaultInputValue={defaultInputValue}
            cacheOptions
            id={name}
            placeholder={getPlaceholder({
                placeholder,
                Icon
            })}
            loadOptions={fetchSuggestionsDebounced}
            onChange={onAddressChange}
        />
    </div>;
};

export default SelectLocation;