import classNames from "classnames";
import { GrClose, GrMenu } from "react-icons/gr";

import styles from "../styles/ToggleBtn.module.scss";



type Props = {
    isOpen: boolean,
    setIsOpen: any,
}

const ToggleBtn = ({
    isOpen,
    setIsOpen
}: Props) => {

    return <div
        className={
            classNames(styles.toggleBtn, {
                [styles.isOpen]: isOpen
            })}
        onClick={() => setIsOpen(!isOpen)}
    >
        {isOpen ? <GrClose /> : <GrMenu />}
    </div>;
};

export default ToggleBtn;