import { useRouter } from 'next/router';
import {
    JSXElementConstructor,
    ReactElement,
    useContext,
    useEffect,
    useState,
} from 'react';
import { AuthContext } from "@/context/authContext";

type Props = {
    children: ReactElement<unknown, string | JSXElementConstructor<unknown>>;
}
const RouteGuard = ({
    children
}: Props) => {
    const [authorized, setAuthorized] = useState(true);
    const authContext = useContext(AuthContext);
    const router = useRouter();
    const {
        isLogged,
        authStatus
    } = authContext;


    const authCheckAndRedirect = () => {        
        if (
            !isLogged()
        ) {
            setAuthorized(false);
            router.push({
                pathname: '/',
            });
        } else {
            setAuthorized(true);
        }
    };




    useEffect(() => {
        authCheckAndRedirect()
    }, []);

    useEffect(() => {
        authCheckAndRedirect()
    }, [authStatus]);

    return <div> {authorized ? (
        children
    ) : (
        "..."
    )}
    </div>
};

export default RouteGuard;