

export enum CAR_PARAMS {
    ENGINE = "car.parameters.engine",
    TRANSMISSION = "car.parameters.transmission",
    FUEL = "car.parameters.fuel",
    HORSEPOWER = "car.parameters.horsePower",
    SEATS = "car.parameters.seats",
    DRIVE = "car.parameters.drive",
    COLOR = "car.parameters.color",
    ACCELERATION = "car.parameters.acceleration",
    PRODUCTION_YEAR = "car.parameters.productionYear",
    CONSUMPTION = "car.parameters.consumption",
    VMAX = "car.parameters.vmax",
    BODY_TYPE = "car.parameters.body",
}

export enum CAR {
    DESCRIPTION = "car.description",
    BRAND = "car.brand",
    MODEL = "car.model",
    VERSION = "car.version",
    PHOTOS = "car.photos",
    PRICE_LIST = "costs.priceList",
    STATUS = "car.status"
}

export enum PRICE_LIST {
    COST = "costs.priceList.cost",
    RANGE_TOP = "costs.priceList.rangeTop",
    RANGE_BOTTOM = "costs.priceList.rangeBottom",
    CURRENCY = "costs.currency",
}

export enum COST_LIST {
    BAIL_COST = "costs.additional.bailCost",
    DISTANCE_LIMIT = "costs.additional.distanceLimit",
    PRICE_ABOVE_LIMIT = "costs.additional.priceAboveLimit",
}

export const DRIVE_ENUM = {
    FWD: "FWD",
    RWD: "RWD",
    AWD: "AWD",
} as const;

export const COLOR_ENUM = {
    BLACK: "BLACK",
    SILVER: "SILVER",
    RED: "RED",
    GREEN: "GREEN",
    BLUE: "BLUE",
    WHITE: "WHITE",
    OTHER: "OTHER"
} as const;

export const TRANSMISSION_ENUM = {
    AUTOMATIC: "AUTOMATIC",
    MANUAL: "MANUAL"
} as const;

export const FUEL_ENUM = {
    PETROL: "PETROL",
    DIESEL: "DIESEL",
    LPG: "LPG",
    EV: "EV",
    HYBRID: "HYBRID",
} as const;

export const BODY_TYPE_ENUM = {
    HOTHATCH: "HOTHATCH",
    HATCHBACK: "HATCHBACK",
    MINIVAN: "MINIVAN",
    COUPE: "COUPE",
    SUPERCAR: "SUPERCAR",
    CABRIO: "CABRIO",
    SEDAN: "SEDAN",
    CAMPER: "CAMPER",
    SUV: "SUV",
    BUS: "BUS",
    COMBI: "COMBI"
} as const;

export const STATUS_ENUM = {
    AVAILABLE: "AVAILABLE",
    UNAVAILABLE: "UNAVAILABLE"
} as const;