
import { AddCompanySchema } from "@/utils/validators/validators";
import useTranslation from "next-translate/useTranslation";
import {
    Input,
    CompanyAddressForm,
    CompanyContactForm,
    Upload,
    FormWrapper
} from "@/c/Form";
import {
    FormProvider,
    useForm,
} from "react-hook-form";
import { COMPANY } from "@/enum";
import { zodResolver } from "@hookform/resolvers/zod";
import { serverApi } from "@/utils/api";
import config from "@/config";

import { getCompanySlug } from "@/utils/getters";
import { useRouter } from "next/router";
import { get } from "lodash";
import { Loader } from "../Layout";
import { useState } from "react";
import { useSlack } from "@/hooks/index";

import styles from "../Form/atoms/styles/Form.module.scss";

type Props = {
    type?: "ADD" | "UPDATE",
    values?: any,
    id?: string | number
}

const CompanyForm = ({
    type = "ADD",
    values,
    id
}: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const router = useRouter();
    const { send } = useSlack("CompanyForm");
    const isUpdate = type === "UPDATE";
    const methods = useForm({
        resolver: zodResolver(AddCompanySchema),
        ...(isUpdate && {
            defaultValues: {
                ...values
            }
        })
    });

    const { handleSubmit, formState: { errors } } = methods;
    console.log({ errors });

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        try {
            const {
                urls: {
                    user: {
                        addCompany: addCompanyUrl,
                        updateCompany = ""
                    } = {}
                } = {}
            } = config;

            if (addCompanyUrl === undefined) {
                console.error("addCompanyUrl is undefined");
                return;
            }

            const { data: { company } = {} } = isUpdate ? await serverApi.post(updateCompany, {
                id,
                ...data
            }) : await serverApi.post(addCompanyUrl as string, data); //hack - jest zabezpieczone undefined wyżej ale build pada
            console.log({ company });

            const companySlug = getCompanySlug(company);

            send({ msg: `${type} | ${companySlug}` });
            router.replace(`/company/${companySlug}`);
        } catch (e) {
            const errorStatus = get(e, "response.status");
            const errorMsg = get(e, "response.data.message");

            send({ msg: `${type} | ${JSON.stringify(errorMsg, undefined, 2)} - ${errorStatus}` });
            console.error("Add company error", e);
            console.error({ errorStatus, errorMsg });

            if (errorStatus === 403) {
                router.reload();
            }

            setIsLoading(false);
        }
    };

    return <Loader
        isLoading={isLoading}
        isFullPage
    >
        <FormProvider {...methods} >
            <FormWrapper
                title={isUpdate
                    ? t("common:editCompanyDataHeader")
                    : t("common:addCompanyTitle")}
                onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.row}>
                    <div className={styles.col12}>
                        <Input
                            name={COMPANY.name}
                            required={true}
                        />
                    </div>
                    <div className={styles.col12}>
                        <Upload
                            name={COMPANY.coverPhoto}
                        />
                    </div>
                </div>
                <Input
                    name={COMPANY.description}
                    inputType="textarea"
                />
                <CompanyAddressForm
                    isError={!!errors?.companyAddress}
                    initialAddress={values?.address}
                    required={true}
                />
                <CompanyContactForm
                    isError={!!errors?.contact?.message}
                />
            </FormWrapper>
        </FormProvider>
    </Loader>;
};

export default CompanyForm;