import React from "react";
import classNames from "classnames";
import { motion } from "framer-motion";
import { Logo, SideDrawerFooter, ToggleBtn } from ".";

import styles from "./styles/SideDrawer.module.scss";

const variants = {
    open: { opacity: 1, x: "0px" },
    closed: { opacity: 0, x: "100%" },
};

type Props = {
    children: any,
    isOpen: boolean,
    isScrollOnTop: boolean,
    setIsOpen: any
}

const SideDrawer = ({
    children,
    isOpen,
    setIsOpen,
    isScrollOnTop
}: Props) => {
    const modifiedChildren = React.cloneElement(children, { closeDrawer: () => setIsOpen(false) });

    return <>
        <motion.div
            className={classNames(styles.drawer, {
                [styles.navOpen]: isScrollOnTop
            })}
            animate={isOpen ? "open" : "closed"}
            initial={false}
            variants={variants}
            onPan={(_, pointInfo) => {
                if (pointInfo?.delta?.x > 0) {
                    setIsOpen(false);
                }
            }}
        >
            <div className={
                classNames(styles.drawerHeader, {
                    [styles.navHeaderOpen]: isScrollOnTop
                })}>
                <Logo option="light"/>
                <ToggleBtn
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                />
            </div>
            <div className={styles.childrenContainer}>
                {modifiedChildren}
            </div>
            <SideDrawerFooter
                isOpen={isOpen}
                setIsOpen={setIsOpen}
            />
        </motion.div>
    </>;
};

export default SideDrawer;
