import classNames from "classnames";
import styles from "../styles/Loader.module.scss";


type Props = {
    delay?: number,
    children: React.ReactNode,
    isLoading?: boolean,
    isFullPage?: boolean
}

const Loader = ({
    // delay = 0,
    children,
    isLoading = false,
    isFullPage = false
}: Props) => {

    return isLoading ? <div className={classNames(styles.wrapper, {
        [styles.isFullPage]: isFullPage
    })}>
        <div
            className={styles.loader}
        />
    </div>
        : <>{children}</>;
};

export default Loader;