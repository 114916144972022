import axios from "axios";
import config from "@/config";
import queryString from "query-string";
import { omitBy, isNull, get } from "lodash";
import { CompanyAddressGeo } from "@/types";
import { companyAddressParser } from "../parsers";

const { findAddressCandidates } = config;

type Props = {
    value?: string | null,
    magicKey?: string | null,
    maxLocations?: number,
    id?: number | null
}
// The magicKey value is not permanent across versions of the geocoding service and should not be stored by a client application

export const getPlaceCoords = async ({
    value = null,
    magicKey = null,
    maxLocations = 5,
    id
}: Props = {}): Promise<CompanyAddressGeo | undefined> => {
    // case when we have to fetch more info about selected localization
    if (!magicKey && !value && id) return undefined;
    const queryObj = omitBy({
        value,
        magicKey,
        maxLocations,
        f: "json",
        outFields: "PlaceName, Type, City, Subregion, Region, Country, Postal, StName, AddBldg, AddNum"
    }, isNull);
    const query = queryString.stringify(queryObj);
    try {
        const { data } = await axios.get(`${findAddressCandidates}?${query}&token=${process.env.NEXT_PUBLIC_ARCGIS_APITOKEN}`);
        const addressObj = get(data, "candidates.[0]", null);

        if (addressObj) {
            return companyAddressParser(addressObj);
        } else {
            throw {
                message: "Place details not found"
            };
        }
    } catch (e) {
        console.error("Place coords doesn't find for ", value, magicKey);
        console.error("Place coords error ", e);
        return;
    }
};