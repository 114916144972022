import useTranslation from "next-translate/useTranslation";
import { BsCheckLg } from "react-icons/bs";
 
import styles from "../styles/SearchBoxAdvantages.module.scss";

const SearchBoxAdvantages = () => {
    const { t } = useTranslation();

    return <div className={styles.searchBoxAdvantages}>
        <Bullet text={t("common:search.wholeCountry") }/>
        <Bullet text={t("common:search.diffBrands") }/>
        <Bullet text={t("common:search.allTypes") }/>
    </div>;
};

type BulletProps = {
    text: string
};

const Bullet = ({ text }: BulletProps) => <div
    className={styles.bullet}
>
    <BsCheckLg />
    {text}
</div>;

export default SearchBoxAdvantages;