import { noop } from "lodash";
import classNames from "classnames";
import ReactTooltip from "react-tooltip";

import styles from "./styles/Button.module.scss";

type Props = {
    children: any,
    color?: string,
    type?: "button" | "submit",
    onClick?: () => void,
    text?: string,
    kind?: "primary" | "link" | "text",
    icon?: JSX.Element,
    size?: "big" | "small" | "normal",
    disabled?: boolean,
    labels?: {
        disabled?: string
    }
}

const Button = ({
    children,
    color = "blue",
    type = "submit",
    onClick = noop,
    text,
    kind = "primary",
    icon,
    size,
    disabled = false,
    labels = {},
    ...props
}: Props) => {
    const ctx = `button-${Math.random()}`;
    const {
        disabled: disabledLabel = null
    } = labels;

    return <>
        <a data-tip data-for={ctx}>
            <button
                className={classNames(
                    styles.btn, size && styles[size],
                    {
                        [styles.btnLink]: kind === "link",
                        [styles.btnPrimary]: kind === "primary",
                        [styles.btnText]: kind === "text",
                    })}
                onClick={onClick}
                color={color}
                type={type}
                disabled={disabled}
                {...props}
            >
                {text || children}
                {icon && <div className={styles.btnIcon}>{icon}</div>}
            </button>
        </a>
        {
            disabled
            && disabledLabel
            && <ReactTooltip
                id={ctx}
                type='info'
                effect='solid'
                place="bottom"
            >
                {disabledLabel}
            </ReactTooltip>
        }
    </>;
};

export default Button;