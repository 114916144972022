import React, { useState, useEffect, useContext } from "react";
import Cookies from "js-cookie";
import * as cognito from "@/hooks/cognito";
import { SignUpProps } from "@/hooks/cognito";

export enum AuthStatus {
    Loading,
    SignedIn,
    SignedOut,
}

export interface IAuth {
    sessionInfo?: { username?: string; email?: string; sub?: string; accessToken?: string; refreshToken?: string }
    attrInfo?: any
    authStatus?: AuthStatus
    signInWithEmail?: any
    signUpWithEmail?: any
    signOut?: any
    verifyCode?: any
    getSession?: any
    sendCode?: any
    forgotPassword?: any
    changePassword?: any
    getAttributes?: any
    setAttribute?: any,
    isLogged?: any
    refreshSession?: any
}

const defaultState: IAuth = {
    sessionInfo: {},
    authStatus: AuthStatus.Loading,
};

type Props = {
    children?: React.ReactNode
}

export const AuthContext = React.createContext(defaultState);

export const AuthIsSignedIn = ({ children }: Props) => {
    const { authStatus }: IAuth = useContext(AuthContext);

    return <>{authStatus === AuthStatus.SignedIn ? children : null}</>;
};

export const AuthIsNotSignedIn = ({ children }: Props) => {
    const { authStatus }: IAuth = useContext(AuthContext);

    return <>{authStatus === AuthStatus.SignedOut ? children : null}</>;
};

const AuthProvider = ({ children }: Props) => {
    const [authStatus, setAuthStatus] = useState(AuthStatus.Loading);
    const [sessionInfo, setSessionInfo] = useState({});
    const [attrInfo, setAttrInfo] = useState([]);

    async function getSessionInfo() {
        try {
            const session: any = await getSession();
            setSessionInfo({
                accessToken: session.accessToken.jwtToken,
                refreshToken: session.refreshToken.token,
            });
            window.localStorage.setItem("accessToken", `${session.accessToken.jwtToken}`);
            window.localStorage.setItem("refreshToken", `${session.refreshToken.token}`);

            Cookies.set("accessToken", session.accessToken.jwtToken);
            Cookies.set("refreshToken", session.refreshToken.token);
            // await setAttribute({ Name: 'website', Value: 'https://github.com/dbroadhurst/aws-cognito-react' })
            const attr: any = await getAttributes();
            setAttrInfo(attr);
            setAuthStatus(AuthStatus.SignedIn);
        } catch (err) {
            setAuthStatus(AuthStatus.SignedOut);
        }
    }

    useEffect(() => {
        getSessionInfo();
    }, [setAuthStatus, authStatus]);



    // if (authStatus === AuthStatus.Loading) {
    //   return children
    // }

    async function signInWithEmail(username: string, password: string) {
        try {
            await cognito.signInWithEmail(username, password);
            setAuthStatus(AuthStatus.SignedIn);
        } catch (err) {
            setAuthStatus(AuthStatus.SignedOut);
            throw err;
        }
    }

    async function signUpWithEmail({ email, username, password, phone }: SignUpProps) { //username: string, email: string, password: string

        try {
            const resp = await cognito.signUpUserWithEmail({ email, username, password, phone });

            return resp;
        } catch (err) {
            throw err;
        }
    }

    function signOut() {
        cognito.signOut();
        setAuthStatus(AuthStatus.SignedOut);
    }

    function isLogged() {
        return authStatus === AuthStatus.SignedIn || authStatus === AuthStatus.Loading;
    }

    async function verifyCode(username: string, code: string) {
        try {
            await cognito.verifyCode(username, code);
        } catch (err) {
            throw err;
        }
    }

    async function getSession() {
        try {
            const session = await cognito.getSession();
            return session;
        } catch (err) {
            throw err;
        }
    }

    async function getAttributes() {
        try {
            const attr = await cognito.getAttributes();
            return attr;
        } catch (err) {
            throw err;
        }
    }

    async function setAttribute(attr: any) {
        try {
            const res = await cognito.setAttribute(attr);
            return res;
        } catch (err) {
            throw err;
        }
    }

    async function sendCode(username: string) {
        try {
            await cognito.sendCode(username);
        } catch (err) {
            throw err;
        }
    }

    async function forgotPassword(username: string, code: string, password: string) {
        try {
            await cognito.forgotPassword(username, code, password);
        } catch (err) {
            throw err;
        }
    }

    async function changePassword(oldPassword: string, newPassword: string) {
        try {
            await cognito.changePassword(oldPassword, newPassword);
        } catch (err) {
            throw err;
        }
    }

    const state: IAuth = {
        authStatus,
        sessionInfo,
        attrInfo,
        signUpWithEmail,
        signInWithEmail,
        signOut,
        verifyCode,
        getSession,
        sendCode,
        forgotPassword,
        changePassword,
        getAttributes,
        setAttribute,
        isLogged
    };

    return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export default AuthProvider;