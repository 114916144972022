import classNames from "classnames";
// import { CurrencyT } from '@/types';

import styles from "./styles/Label.module.scss";

const RequiredAsterix = ({ required = false }: Partial<Props>) => required ? <div className={styles.requiredAsterix}>*</div> : <></>;

type Props = {
    children: any,
    isError?: boolean,
    required?: boolean,
    currencyAnnotation?: boolean,
    name: string,
    disabled?: boolean,
};

const Label = ({
    children,
    isError,
    name,
    required,
    disabled = false,
    // currencyAnnotation
}: Props) => {
    // const currencyLabel: CurrencyT = currencyAnnotation && getValues(PRICE_LIST.CURRENCY);

    return <label
        className={classNames(styles.label, {
            [styles.withError]: isError,
            [styles.disabled]: disabled,
        })}
        htmlFor={name}>

        <RequiredAsterix required={required} />
        {children}
        {/* {currencyAnnotation && <LabelAnnotation> ({currency[currencyLabel]})</LabelAnnotation>} */}
    </label>;
};

export default Label;