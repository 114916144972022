import { CompanyI } from "@/types";
import { isEmpty, omitBy, uniq, isNumber } from "lodash";
import queryString from "query-string";
import { SOCIAL_TYPE_ENUM } from "@/enum";

export * from "./getCarParams";
export * from "./getErrorInfo";
export * from "./getPlaceCoords";
export * from "./getZodMessage";
export * from "./getSlugs";
export * from "./fetchCar";
export * from "./carUtils";
export * from "./companyUtils";

export const getCarBrands = () => [];
export const getCompanyById = (companies: CompanyI[], id: number): CompanyI => {
    const copmany = companies.find(company => company.id === +id);

    //todo ugly
    return copmany as CompanyI;
};

export const getCarModelByBrand = (companies: CompanyI[] | any, brandName: string) => {

    const modelList: string[] = companies
        .reduce(
            (acc: string[], company: CompanyI) => {
                const {
                    cars,
                } = company;
                const models = cars
                    //@ts-ignore
                    .filter(({ brand }) => brand === brandName)
                    .map(({ model }) => model);

                return [...acc, ...models];
            }, []);

    //@ts-ignore
    return uniq(modelList);
};

export const getSearchUrl = (obj: any): string => {
    // remember isEmpty omit numbers!
    const withoutEmptyValues = omitBy(obj, (item) => {
        return isNumber(item) ? false : isEmpty(item);
    });

    return `/search/?${queryString.stringify(withoutEmptyValues)}`;
};

export const getCities = (companies: CompanyI[]): string[] => {
    const cities = companies.map(({
        address: {
            city
        }
    }) => city);

    return uniq(cities);
};

export const getSocialsArray = () => Object.keys(SOCIAL_TYPE_ENUM) as Array<keyof typeof SOCIAL_TYPE_ENUM>;

export const parseUrlToDisplay = (url: string) => {
    if (url?.startsWith("https://")) {
        const https = "https://";
        return url.slice(https.length);
    }

    if (url?.startsWith("http://")) {
        const http = "http://";
        return url.slice(http.length);
    }

    return url;
};

