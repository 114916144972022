import { InputHTMLAttributes, useState } from "react";
import { get } from "lodash";
import classNames from "classnames";
import useTranslation from "next-translate/useTranslation";
import { Translate } from "next-translate";
import { BiShow } from "react-icons/bi";
import { useFormContext } from "react-hook-form";
import { Label } from "./";
import ReactTooltip from "react-tooltip";

import styles from "./styles/Inputs.module.scss";

type ParserProps = {
    val: {
        target: {
            value: string
        }
    },
    parser?: (val: any) => string,
    setValue: any,
    name: string
}

type GetErrorTextProps = {
    name: string,
    errors: any,
    t: Translate
}

const inputParser = ({
    val,
    parser,
    setValue,
    name
}: ParserProps) => {
    const value = val?.target?.value;
    let parsed = value;

    if (parser) {
        parsed = parser(value);
        
        setValue(name, parsed);
    } else {
        setValue(name, value);
    }
};

const getErrorText = ({
    name, errors, t
}: GetErrorTextProps) => {
    const customName = `${name}.custom`;
    const error = get(errors, customName, get(errors, name, false));
    const message = t(`zod:errors.${error?.message}`, {}, t("common:msg.wrongInput"));

    return error ? message : false;
};

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string,
    register?: any,
    name: string,
    currencyAnnotation?: boolean,
    inputType?: "input" | "textarea",
    parser?: (val: any) => string,
    rows?: number;
}
const Input = ({
    label,
    name = "",
    placeholder,
    required,
    inputType = "input",
    type: originType,
    currencyAnnotation,
    disabled,
    parser,
    rows = 4,
    ...rest
}: InputProps) => {
    const { t } = useTranslation();
    const [type, setType] = useState(originType);
    const { register, formState: { errors = {} } = {}, setValue } = useFormContext() || {};
    if (!register) {
        console.error(`Input select ${name} outside context!`);
    }
    const parsedError = getErrorText({ name, errors, t });
    const isError = !!parsedError;

    const placeholderText = placeholder
        ? placeholder
        : t(`forms:placeholder.${name}`, {}, {
            fallback: `forms:placeholder.${name.replace(/[0-9]./g, "")}`
        });

    const getLabel = (label = "") => t(`forms:fields.${label}`, {}, {
        fallback: `forms:fields.${label.replace(/[0-9]./g, "")}`
    });

    return <>
        <a data-tip data-for={name}>
            <div className={styles.inputWrapper}>
                <Label
                    isError={isError}
                    name={name}
                    required={required}
                    disabled={disabled}
                >
                    {label || getLabel(name)}
                </Label>
                {inputType === "input" &&
                    <div className={styles.inputItemWrapper}>
                        <input
                            className={classNames(
                                styles.input,
                                {
                                    [styles.withError]: isError
                                }
                            )}
                            {...(register && name && {
                                ...register(name,
                                    {
                                        ...(type === "number" && { valueAsNumber: true }),
                                    }
                                ),
                            })}
                            type={type}
                            placeholder={placeholderText}
                            disabled={disabled}
                            onChange={val => inputParser({ val, parser, setValue, name })}
                            {...rest}
                        />
                        {
                            originType === "password" && <div
                                className={styles.inputButton}
                                onClick={() => {
                                    setType(type === "password" ? "text" : "password");
                                }}
                            >
                                <BiShow />
                            </div>
                        }
                    </div>
                }
                {
                    inputType === "textarea" && <textarea
                        placeholder={placeholderText}
                        onChange={val => inputParser({ val, parser, setValue, name })}
                        rows={rows}
                        {...(register && name && {
                            ...register(name,
                                {
                                    ...(type === "number" && { valueAsNumber: true }),
                                }
                            ),
                        })}
                        disabled={disabled}
                        className={classNames(
                            styles.textarea,
                            {
                                [styles.withError]: isError
                            }
                        )}

                    />
                }
            </div>
        </a>
        {isError && <ReactTooltip
            id={name}
            type='error'
            effect='solid'
            place="bottom"
        >
            {parsedError}
        </ReactTooltip>
        }
    </>;
};

export default Input;