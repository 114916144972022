import { AuthIsNotSignedIn } from "@/context/authContext";
import { Footer, MobileMenuItem, Navbar, SideDrawer } from "./index";
import { useEffect, useState } from "react";
import { useScrollPosition } from "@/hooks";

import styles from "./styles/Layout.module.scss";
import useTranslation from "next-translate/useTranslation";
import config from "@/config";

type Props = {
    children: JSX.Element,
}

const { flags: {
    isBlog = false
} = {} } = config;

const Layout = ({
    children
}: Props) => {
    const scroll = useScrollPosition();
    const { t } = useTranslation();
    const [isScrollOnTop, setIsScrollOnTop] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);


    useEffect(() => {
        setIsScrollOnTop(scroll < 100);
    }, [scroll]);

    return <div className={styles.layout}>
        <Navbar
            isScrollOnTop={isScrollOnTop}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
        />
        <SideDrawer
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            isScrollOnTop={isScrollOnTop}
        >
            <div onClick={() => {
                setIsOpen(false);
            }}>
                <MobileMenuItem href="/" label={t("common:cars")} />
                {isBlog && <MobileMenuItem href="/blog" label={t("common:blog")} />}
                <MobileMenuItem href="/kontakt" label={t("common:contact")} />
                <br />
                <AuthIsNotSignedIn>
                    <MobileMenuItem href="/auth/login" label={t("common:btn.login")} />
                    <MobileMenuItem href="/auth/register" label={t("common:btn.registerAccount")} />
                </AuthIsNotSignedIn>
            </div>
        </SideDrawer>
        {children}
        <AuthIsNotSignedIn>
            <Footer />
        </AuthIsNotSignedIn>
    </div>;
};

export default Layout;