import { useState } from "react";
import config from "@/config";
import { CarSchemaAdd, CarSchemaUpdate } from "@/utils/validators/validators";
import useTranslation from "next-translate/useTranslation";
// import { serverApi } from '@/utils/api';
import {
    FormProvider,
    useForm,
} from "react-hook-form";
import { CAR } from "@/enum";
import { Select, Input, CarParamsForm, CarPriceListForm, Upload, FormWrapper } from "@/c/Form";
import { get } from "lodash";
import { zodResolver } from "@hookform/resolvers/zod";
import { serverApi } from "@/utils/api";
import { BrandT } from "@/types";
import styles from "./atoms/styles/Form.module.scss";
import { useRouter } from "next/router";
import { getCarSlug } from "@/utils/getters";
import { Loader } from "../Layout";
import { useSlack } from "@/hooks/index";

const { urls: { car = {} } } = config;
type Props = {
    type?: "ADD" | "UPDATE",
    values?: any,
    id?: string | number,
    brands?: BrandT[]
    companyId?: number
}

const CarForm = ({
    type = "ADD",
    id,
    values = {},
    brands = [],
    companyId
}: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const {
        car: {
            brand = {},
            photos = [],
            ...carValues
        } = {},
        ...defaultValues
    } = values;
    const isUpdate = type === "UPDATE";

    const { send } = useSlack("CarForm");
    const { t } = useTranslation();
    const router = useRouter();
    const methods = useForm({
        criteriaMode: "all",
        resolver: zodResolver(isUpdate ? CarSchemaUpdate : CarSchemaAdd),
        ...(isUpdate ? {
            defaultValues: {
                car: {
                    ...carValues,
                    photos: get(photos, "[0].url", photos)
                },
                ...defaultValues
            }
        } : {
            defaultValues: {
                car: {
                    status: "AVAILABLE"
                }
            }
        }
        )
    });
    const { handleSubmit } = methods;

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        try {
            //@ts-ignore
            const url = isUpdate ? car?.updateCar : car?.addCar;
            //@ts-ignore
            const { data: { car: carData } = {} } = await serverApi.post(url, {
                ...data,
                companyId,
                id
            });
            send({ msg: carData });
            const carUrl = getCarSlug(carData);
            carUrl && router.replace(`/car/${carUrl}`);
            setIsLoading(false);
        } catch (e) {
            const msg = get(e, "response.data.message", null);
            const status = get(e, "response.status", null);
            //todo obsługa
            console.error("Car form", { e, msg, status });
            setIsLoading(false);
            send({ msg: `${msg} - ${status} - ${JSON.stringify(data)}` });
        }
    };

    return <Loader
        isLoading={isLoading}
        isFullPage
    >
        <FormProvider {...methods} >
            <FormWrapper
                onSubmit={handleSubmit(onSubmit)}
                title={t(`common:car.${type}`)}
            >
                <>
                    {/* @ts-ignore */}
                    <div className={styles.formRow}>
                        {!isUpdate && <Select
                            name={CAR.BRAND}
                            required
                            options={brands.map(brand => ({
                                label: brand?.name,
                                value: brand?.id
                            }))}
                        />}
                        <Input
                            name={CAR.MODEL}
                            required
                        />
                        <Input
                            name={CAR.VERSION}
                        />
                        <Upload
                            name={CAR.PHOTOS}
                            // required={true}
                            labelPath="forms:fields.car.coverPhoto"
                        />
                    </div>
                    <Input
                        name={CAR.DESCRIPTION}
                        inputType="textarea"
                    />
                    <CarParamsForm />
                    <CarPriceListForm />
                </>
            </FormWrapper>
        </FormProvider>
    </Loader>;
};

export default CarForm;