import { DRIVE_ENUM, COLOR_ENUM, TRANSMISSION_ENUM, FUEL_ENUM, BODY_TYPE_ENUM, STATUS_ENUM } from "@/enum";
import { sortBy } from "lodash";

export const getDriveArray = () => Object.keys(DRIVE_ENUM) as Array<keyof typeof DRIVE_ENUM>;
export const getColorsArray = () => Object.keys(COLOR_ENUM) as Array<keyof typeof COLOR_ENUM>;
export const getTransmissionArray = () => Object.keys(TRANSMISSION_ENUM) as Array<keyof typeof TRANSMISSION_ENUM>;
export const getFuelArray = () => Object.keys(FUEL_ENUM) as Array<keyof typeof FUEL_ENUM>;
export const getBodyArray = () => Object.keys(BODY_TYPE_ENUM) as Array<keyof typeof BODY_TYPE_ENUM>;
export const getStatusArray = () => Object.keys(STATUS_ENUM) as Array<keyof typeof STATUS_ENUM>;

export const getCarParams = () => {
    return {
        drive: sortBy(getDriveArray()),
        colors: getColorsArray(),
        transmission: sortBy(getTransmissionArray()),
        fuel: sortBy(getFuelArray()),
        body: sortBy(getBodyArray()),
        status: sortBy(getStatusArray()),
    };
};