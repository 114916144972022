import classNames from "classnames";
import styles from "../styles/CarList.module.scss";

type Props = {
    children: React.ReactNode[] | React.ReactNode,
    size?: "small" | "normal" | "big",
    kind?: "list" | "tiles"
}

const CarList = ({
    children,
    size = "normal",
    kind = "tiles"
}: Props) => {

    return <div className={classNames(
        styles.carList,
        styles[size],
        styles[kind])}
    >
        {children}
    </div>;
};

export default CarList;