import config from "@/config";
import { CarsAndCostsT, CompanyI, FilterCarProps, FilterCompanyProps } from "@/types";
import { isArray } from "lodash";
import { serverApi } from "../api";
import { carParser, companyParser } from "../parsers";

const { urls: { car: carUrls, user } } = config;

type idT = number | string | string[];

export const fetchCarById = async (id: idT, withOwner = true): Promise<CarsAndCostsT> => {
    const carId = isArray(id) ? +id[0] : +id;

    const { data: { cars = [] } = {} } = await serverApi.get(carUrls?.getList, {
        params: {
            filter: {
                id: carId
            },
            withOwner
        }
    });
    const car = isArray(cars) ? cars[0] : {};

    return carParser(car);
};

export const fetchCompanyById = async (id: idT) => {
    const companyId = isArray(id) ? +id[0] : +id;

    const { data: { company = {} } = {} } = await serverApi.get(`${user?.getCompany}/${companyId}`);

    return company;
};

export const fetchFilteredCompanies = async ({ city }: FilterCompanyProps) => {
    //todo filter by city itp 
    const { data: {
        companies : {
            companies = []
        } = {}
    } = {}
    } = await serverApi.get(user?.getCompanies, {
        params: {
            filter: {
                city
            }
        }
    });

    // na pewno dobrze jest użyć CompanyI?
    return companies.map((company: CompanyI) => companyParser(company));
};

export const fetchFilteredCars = async ({ car = {}, place = {} }: FilterCarProps) => {
    try {
        const { data: { cars = [] } = {} } = await serverApi.get(carUrls?.getList, {
            params: {
                filter: {
                    car,
                    place
                },
            }
        });

        return cars.map((car: unknown) => carParser(car));
    } catch (e) {
        console.error("[fetchFilteredCars] ", e);
        return [];
    }
};

