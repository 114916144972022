import Link from "next/link";
import classNames from "classnames";

import styles from "../styles/Logo.module.scss";

type Props = {
    isMainPage?: boolean,
    option?: "color" | "light"
}

const Logo = ({
    isMainPage = true,
    option = "color"
}: Props) => {
    const isWhite = option === "light";
    const logoUrl = isWhite ? "/static/logo/white_min.png" : "/static/logo/color_min.png";

    return <Link href="/">
        <div className={classNames(styles.logo, {
            [styles.white]: isWhite
        })}>
            {
                isMainPage ?
                    <h1>
                        <img
                            className={styles.logoImg}
                            src={logoUrl} alt="" />
                    </h1>
                    : <img
                        className={styles.logoImg}
                        src={logoUrl} alt="" />
            }
        </div>
    </Link>;
};

export default Logo;