import { serverApi } from "@/utils/api";
import {
    AuthenticationDetails,
    CognitoUser,
    CognitoUserAttribute,
    CognitoUserPool
} from "amazon-cognito-identity-js";

const userPoolId = process.env.NEXT_PUBLIC_COGNITO_POOL_ID;
const clientId = process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID;
const envName = process.env.NEXT_PUBLIC_API;

const poolData = {
    UserPoolId: `${userPoolId}`,
    ClientId: `${clientId}`,
};

const Urls = {
    register: "/user/register"
};

const userPool: CognitoUserPool = new CognitoUserPool(poolData);

let currentUser: any = userPool.getCurrentUser();

export type SignUpProps = {
  username: string,
  email: string,
  password: string,
  phone: string
}

export function getCurrentUser() {
    return currentUser;
}

function getCognitoUser(username: string) {
    const userData = {
        Username: username,
        Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    return cognitoUser;
}

export async function getSession() {
    if (!currentUser) {
        currentUser = userPool.getCurrentUser();
    }

    return new Promise(function (resolve, reject) {
        currentUser.getSession(function (err: any, session: any) {
            if (err) {
                reject(err);
            } else {
                resolve(session);
            }
        });
    }).catch((err) => {
        throw err;
    });
}

export async function signUpUserWithEmail({ email, password, phone }: SignUpProps) {
    const phoneNumber = phone.length === 9 ? `+48${phone}` : phone;
    return new Promise(function (resolve, reject) {
        const attributeList = [
            new CognitoUserAttribute({
                Name: "email",
                Value: email,
            }),
            new CognitoUserAttribute({
                Name: "phone_number",
                Value: phoneNumber,
            }),
            new CognitoUserAttribute({
                Name: "address",
                Value: "PL",
            }),
            new CognitoUserAttribute({
                Name: "name",
                Value: envName || "Local",
            }),
        ];

        userPool.signUp(email, password, attributeList, [], function (err, res) {
            if (err) {
                reject(err);
            } else {
                if (res) {
                    const {
                        userSub,
                        user: {
                            //@ts-ignore
                            username = ""
                        } = {}
                    } = res;

                    serverApi.post(Urls.register, {
                        email: username,
                        cognitoId: userSub
                    });

                    resolve(res);
                } else {
                    console.error("Register - res is empty");
                }
            }
        });
    }).catch((err) => {
        throw err;
    });
}

export async function verifyCode(username: string, code: string) {
    return new Promise(function (resolve, reject) {
        const cognitoUser = getCognitoUser(username);

        cognitoUser.confirmRegistration(code, true, function (err, result) {
            if (err) {
                reject(err);
            } else {
                resolve(result);
            }
        });
    }).catch((err) => {
        throw err;
    });
}

export async function signInWithEmail(username: string, password: string) {
    return new Promise(function (resolve, reject) {
        const authenticationData = {
            Username: username,
            Password: password,
        };
        const authenticationDetails = new AuthenticationDetails(authenticationData);

        currentUser = getCognitoUser(username);
        currentUser.authenticateUser(authenticationDetails, {
            onSuccess: function (res: any) {
                resolve(res);
            },
            onFailure: function (err: any) {
                reject(err);
            },
        });
    }).catch((err) => {
        console.error("LOGIN CATCH", { err });
        throw err;
    });
}

export function signOut() {
    if (currentUser) {
        currentUser.signOut();
    }
}

export async function getAttributes() {
    return new Promise(function (resolve, reject) {
        currentUser?.getUserAttributes(function (err: any, attributes: any) {
            if (err) {
                reject(err);
            } else {
                resolve(attributes);
            }
        });
    }).catch((err) => {
        throw err;
    });
}

export async function setAttribute(attribute: any) {
    return new Promise(function (resolve, reject) {
        const attributeList = [];
        const res = new CognitoUserAttribute(attribute);
        attributeList.push(res);

        currentUser.updateAttributes(attributeList, (err: any, res: any) => {
            if (err) {
                reject(err);
            } else {
                resolve(res);
            }
        });
    }).catch((err) => {
        throw err;
    });
}

export async function sendCode(username: string) {
    return new Promise(function (resolve, reject) {
        const cognitoUser = getCognitoUser(username);

        if (!cognitoUser) {
            reject(`could not find ${username}`);
            return;
        }

        cognitoUser.forgotPassword({
            onSuccess: function (res) {
                resolve(res);
            },
            onFailure: function (err) {
                reject(err);
            },
        });
    }).catch((err) => {
        throw err;
    });
}

export async function forgotPassword(username: string, code: string, password: string) {
    return new Promise(function (resolve, reject) {
        const cognitoUser = getCognitoUser(username);

        if (!cognitoUser) {
            reject(`could not find ${username}`);
            return;
        }

        cognitoUser.confirmPassword(code, password, {
            onSuccess: function () {
                resolve("password updated");
            },
            onFailure: function (err) {
                reject(err);
            },
        });
    });
}

export async function changePassword(oldPassword: string, newPassword: string) {
    return new Promise(function (resolve, reject) {
        currentUser.changePassword(oldPassword, newPassword, function (err: any, res: any) {
            if (err) {
                reject(err);
            } else {
                resolve(res);
            }
        });
    });
}