import { useState, useEffect } from "react";
import useTranslation from "next-translate/useTranslation";
import { AddressFormDetails, SelectLocation } from "./";
import { CompanyAddressGeo, CompanyGeoAddressI } from "@/types";
import { getStreetAddress } from "@/utils/getters";

type Props = {
    initialAddress?: any;
    isError?: boolean,
    required?: boolean
}

const getDefaultTextValue = (init: CompanyGeoAddressI) => {
    const {
        city,
        formattedAddress
    } = init || {};
    if (formattedAddress) return formattedAddress;
    const address = getStreetAddress(init);

    if (city) return `${city}, ${address}`;

    return "";
};
const CompanyAddressForm = ({
    initialAddress = {},
    isError,
    required,
}: Props) => {
    const [details, setDetails] = useState<CompanyAddressGeo | undefined>();
    const { t } = useTranslation();

    const handleAddressChange = (fetchedDetails: any) => {
        setDetails({...fetchedDetails});
    };

    useEffect(() => {
        if (initialAddress) {
            setDetails(initialAddress);
        }
    }, []);

    return <div>
        <SelectLocation
            name="addressSelect"
            type="companyAddress"
            handleAddressChange={handleAddressChange}
            placeholder={t("forms:placeholder.address")}
            label={t("forms:fields.companyAddressLabel")}
            isErrorAddress={isError}
            required={required}
            defaultInputValue={getDefaultTextValue(initialAddress)}
        />
        {details &&
            <AddressFormDetails details={details} />
        }
    </div>;
};


export default CompanyAddressForm;