import { CAR } from "@/utils/enums";
import { CloseButton, Input } from ".";
import styles from "../atoms/styles/Form.module.scss";

const CarPriceRow = ({
    index,
    remove = () => { }
}: {//todo
    index?: number,
    remove?: any
}) => {

    return <div className={styles.priceRow}>
        <Input
            name={`${CAR.PRICE_LIST}.${index}.cost`}
            type="number"
            required
            currencyAnnotation
            min={0}
        />
        <Input
            name={`${CAR.PRICE_LIST}.${index}.rangeBottom`}
            type="number"
            required
            min={0}
        />
        <Input
            name={`${CAR.PRICE_LIST}.${index}.rangeTop`}
            type="number"
            required
            min={0}
        />
        {
            index !== 0 && <div
                onClick={() => remove(index)}
                className={styles.closeButton}
            >
                <CloseButton />
            </div>
        }
    </div>;
};

export default CarPriceRow;
