
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { useEffect } from "react";
import Head from "next/head";
import type { AppProps } from "next/app";
import CookieConsent from "react-cookie-consent";
import { Layout } from "@/c/Layout";
import useTranslation from "next-translate/useTranslation";
import TagManager from "react-gtm-module";
import AuthProvider from "@/context/authContext";
import DataProvider from "@/context/dataContext";
import "../styles/index.scss";

function MyApp({ Component, pageProps }: AppProps) {
    const { t } = useTranslation();

    useEffect(() => {
        const tagManagerOptions = {
            gtmId: (process.env.NODE_ENV === "production") ? "GTM-PJGVQ3C" : "X",
        };
        TagManager.initialize(tagManagerOptions);
    }, []);

    return <>
        <AuthProvider>
            <DataProvider>
                <Layout>
                    <>
                        <Head>
                            <link rel="preconnect" href="https://fonts.googleapis.com"></link>
                            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true"></link>
                            <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700;900&display=swap" rel="stylesheet"></link>
                            <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,500;0,700;1,400&display=swap" rel="stylesheet"></link>
                        </Head>
                        <noscript>
                            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PJGVQ3C"
                                height="0" width="0" style={{ display: "none", visibility: "hidden" }}></iframe>
                        </noscript>
                        <Component {...pageProps} />
                    </>
                </Layout>
            </DataProvider>
        </AuthProvider>
        <CookieConsent
            location="bottom"
            buttonText="Ok"
            cookieName="cookieConsent"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
        >
            {t("common:cookieConsent")}
        </CookieConsent>
    </>;
}

const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  