import imageCompression from "browser-image-compression";

type SizeType = "big" | "medium" | "small";

const imageCompressor = async (file: any, size: SizeType = "medium") => {
    const imageFile = file;
    const sizes = {
        big: 2,
        medium: 1,
        small: 0.5
    };
    console.warn(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    const options = {
        maxSizeMB: sizes[size],
        maxWidthOrHeight: 1680,
        useWebWorker: true,
        initialQuality: 0.7
    };
    try {
        const compressedFile = await imageCompression(imageFile, options);
        console.warn(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

        return compressedFile;
    } catch (error) {
        console.error("Image Compression", error);
    }

};
export default imageCompressor;