import classNames from "classnames";
import styles from "../styles/Header.module.scss";

type Props = {
    variant: "h1" | "h2" | "h3" | "h4" | "h5",
    children: JSX.Element | string,
    topText?: string,
    spaceTop?: boolean;
    spaceBottom?: boolean;
    isBold?: boolean;
}

const Header = ({
    variant = "h3",
    children,
    topText,
    spaceTop = false,
    spaceBottom = false,
    isBold = false
}: Props) => {

    return <div className={classNames({
        [styles.spaceTop]: spaceTop,
        [styles.spaceBottom]: spaceBottom
    })}>
        {topText && <p className={styles.topText}>{topText}</p>}
        <p
            className={classNames(styles[variant], {
                [styles.isBold]: isBold
            })}
        >
            {children}
        </p>
    </div>;
};

export default Header;