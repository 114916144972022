import { getSession } from "@/hooks/cognito";
import axios from "axios";
import Cookies from "js-cookie";


const serverApi = axios.create({
    baseURL: process.env.NEXT_PUBLIC_API,
    withCredentials: true,
    xsrfCookieName: "accessToken"
});

serverApi.interceptors.request.use(
    config => {
        if (config) {
            //@ts-ignore
            config.headers["Authorization"] = `Bearer ${Cookies.get("accessToken")}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

serverApi.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, async function (error) {
    const originalRequest = error.config;
    if (error?.response?.status === 403 && !originalRequest?._retry) {
        originalRequest._retry = true;
        const session: any = await getSession();
        window.localStorage.setItem("accessToken", `${session?.accessToken?.jwtToken}`);
        window.localStorage.setItem("refreshToken", `${session?.refreshToken?.token}`);

        Cookies.set("accessToken", session?.accessToken?.jwtToken);
        Cookies.set("refreshToken", session?.refreshToken?.token);

        return serverApi(originalRequest);
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});

export {
    serverApi
};