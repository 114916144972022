import { CAR, CAR_PARAMS } from "@/utils/enums";
import { getCarParams } from "@/getters";
import useTranslation from "next-translate/useTranslation";
import { Input, Select } from ".";
import { upperFirst } from "lodash";
import { Header } from "../Layout";

import styles from "./atoms/styles/Form.module.scss";

const CarParamsForm = () => {
    const { t } = useTranslation();
    const {
        transmission,
        body,
        colors,
        drive,
        fuel,
        status
    } = getCarParams();

    const xmas = new Date();
    const maxYear = xmas.getFullYear();

    return <div>
        <Header
            variant="h4"
            spaceBottom
            spaceTop
        >{t("common:parameters")}</Header>
        <div className={styles.formRow}>
            <Input
                name={CAR_PARAMS.ENGINE}
            />
            <Input
                name={CAR_PARAMS.SEATS}
                type="number"
                min={0}
            />
            <Input
                name={CAR_PARAMS.CONSUMPTION}
                type="number"
                min={0}
            />
            <Input
                name={CAR_PARAMS.PRODUCTION_YEAR}
                type="number"
                min={1950}
                max={maxYear}
            />
        </div>
        <div className={styles.formRow}>
            <Input
                name={CAR_PARAMS.ACCELERATION}
                type="number"
                min={0}
                step="0.01"
            />
            <Input
                name={CAR_PARAMS.VMAX}
                type="number"
                min={0}
            />
            <Input
                name={CAR_PARAMS.HORSEPOWER}
                type="number"
                min={0}
            />
        </div>
        <div className={styles.formRow}>
            <Select
                name={CAR.STATUS}
                options={
                    status.map(item => ({
                        value: item,
                        label: t(`car:details.status.${item}`),
                    }))
                }
            />
            <Select
                name={CAR_PARAMS.TRANSMISSION}
                options={
                    transmission.map(item => ({
                        value: item,
                        label: t(`car:details.transmission.${item}`),
                    }))
                }
            />
            <Select
                name={CAR_PARAMS.BODY_TYPE}
                options={
                    body.map(item => ({
                        value: item,
                        label: upperFirst(
                            t(`car:details.body.${item}`)
                                .toLowerCase()
                        ),
                    }))
                }
            />
        </div>
        <div className={styles.formRow}>
            <Select
                name={CAR_PARAMS.DRIVE}
                options={
                    drive.map(item => ({
                        value: item,
                        label: t(`car:details.drive.${item}`),
                    }))
                }
            />
            <Select
                name={CAR_PARAMS.FUEL}
                options={
                    fuel.map(item => ({
                        value: item,
                        label: t(`car:details.fuel.${item}`),
                    }))
                }
            />
            <Select
                name={CAR_PARAMS.COLOR}
                options={
                    colors.map(item => ({
                        value: item,
                        label: t(`car:details.color.${item}`),
                    }))
                }
            />
        </div>
    </div>;
};

export default CarParamsForm;