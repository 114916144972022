
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import styles from "./styles/Footer.module.scss";

const Footer = () => {
    const {t} = useTranslation();
    
    return <footer className={styles.footer}>
        <Link href="/kontakt">
            {
                t("common:contactRent")
            }
        </Link>
    </footer>;
};

export default Footer;