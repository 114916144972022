import styles from "./styles/Checkbox.module.scss";

type Props = {
    checked: boolean,
    handleChange: any,
    text: JSX.Element,
};

const Checkbox = ({
    checked,
    handleChange,
    text,
}: Props) => {

    return <label className={styles.checkbox}>
        <input
            type="checkbox"
            checked={checked}
            onChange={handleChange}
        />
        <div className={styles.checkboxLabel}>
            {text}
        </div>
    </label>;
};

export default Checkbox;