import config from "@/config";
import { BsInstagram } from "react-icons/bs";

import styles from "../styles/SideDrawer.module.scss";

type Props = {
    withLabel?: boolean
}

const {
    socials: {
        instagram
    } = {}
} = config;
const OwnSocials = ({
    withLabel = true
}: Props) => {
    //

    return <div className={styles.drawerFooter}>
        {withLabel && <div className={styles.drawerFooterTitle}>
            Social media
        </div>
        }
        <div className={styles.drawerFooterList}>
            <a href={instagram}>
                <BsInstagram />
            </a>
        </div>
    </div>;
};

export default OwnSocials;