import { SocialT, SocialsU } from "src/types/Company";
import { FaFacebook, FaTiktok, FaTwitter } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { isEmpty } from "lodash";
import { parseUrlToDisplay } from "@/utils/getters";
import useTranslation from "next-translate/useTranslation";

import styles from "./styles/Contact.module.scss";
import s from "./styles/Socials.module.scss";

type Props = {
    list?: SocialT[] | [],
    type?: "ROW" | "LIST"
}

type IconsT = {
    [key in SocialsU]: any
}

const Icons: IconsT = {
    FACEBOOK: FaFacebook,
    INSTAGRAM: AiFillInstagram,
    TIKTOK: FaTiktok,
    TWITTER: FaTwitter
};

const Socials = ({
    list = [],
    type = "ROW"
}: Props) => {
    const { t } = useTranslation();

    if (isEmpty(list)) return <></>;

    if (type === "LIST") {


        return <div className={s.socialsList}>
            <div className={s.title}>
                {t("common:socialMedia")}
            </div>
            {
                list.map(({
                    type, url, name
                }: SocialT) => {
                    const Icon = Icons[type];
                    const link = `https://${parseUrlToDisplay(url)}`;

                    return <a
                        href={link}
                        key={link}
                        target="_blank"
                        rel="noreferrer"
                    > <div className={s.item}>
                            <Icon size={18} />
                            {name}
                        </div>
                    </a>;
                })}
        </div>;
    }


    return <div className={styles.directContact}>
        {
            list.map(({
                type, url
            }: SocialT) => {
                if (Object.keys(Icons)?.includes(type)) {
                    const Icon = Icons[type];
                    const link = `https://${parseUrlToDisplay(url)}`;

                    return <div
                        className={styles.contactRow}
                        key={url}
                    >
                        <a href={link}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Icon size={24} />
                        </a>
                    </div>;
                }
                return;
            })
        }
    </div>;
};

export default Socials;