import dynamic from "next/dynamic";

export { default as Header } from "./Header";
export { default as ToggleBtn } from "./ToggleBtn";
export { default as SideDrawerFooter } from "./SideDrawerFooter";
export { default as OwnSocials } from "./OwnSocials";
export { default as Loader } from "./Loader";
export { default as Logo } from "./Logo";
export { default as Section } from "./Section";
export { default as Accordion } from "./Accordion";
export { default as CarList } from "./CarList";
export { default as MobileMenuItem } from "./MobileMenuItem";
export { default as SearchBoxAdvantages } from "./SearchBoxAdvantages";

const MapComponent = dynamic(() => import("./Map"), {
    ssr: false,
});

export { MapComponent };