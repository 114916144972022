import { useState, useEffect } from "react";
import config from "@/config";
import { serverApi } from "@/utils/api";
import { isArray } from "lodash";
import { CompanyI } from "@/types";

const { urls: { user } } = config;


function useCompany(companyId?: string | string[] | number) {
    const [company, setCompany] = useState<CompanyI>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);

    const fetchCompany = async () => {
        if (companyId) {
            try {
                const id = isArray(companyId) ? companyId[0] : companyId;
                const { data: { company: companyData } = {} } = await serverApi.get(`${user?.getCompany}/${id}`);

                setCompany(companyData);
                setLoading(false);
            } catch (e) {
                console.error(`Fetch company ${companyId}`, e);
                setLoading(false);
                setError(true);
            }
        } else {
            console.error("Company id is undefined");
            
            setLoading(false);
            setError(true);
        }
    };

    useEffect(() => {
        fetchCompany();
    }, []);

    return {
        company,
        loading,
        error
    };
}

export default useCompany;