import { MapComponent } from "@/c/Layout";
import { CompanyAddressGeo } from "@/types";
import classnames from "classnames";
import { isEmpty } from "lodash";
import useTranslation from "next-translate/useTranslation";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Button, Input } from ".";

import styles from "./styles/Form.module.scss";

type Props = {
    details: CompanyAddressGeo
}
const AddressFormDetails = ({ details }: Props) => {
    // const [addressDetails, setAddressDetails] = useState(details);
    const { t } = useTranslation();
    const methods = useFormContext();
    const { setValue } = methods;
    const [isFormAutoFilled, setIsFormAutoFilled] = useState<boolean>(true);
    const {
        formattedAddress,
        region,
        subregion,
        latitude,
        longitude,
        country,
        ...detailsInputs
    } = details;
    console.log("render", { details });

    const isMapLocalization = !!(latitude && longitude);

    const setInitialFormValues = () => {
        Object.entries(details).forEach(
            ([key, value]) => {
                const inputName = `companyAddress.${key}`;

                setValue(inputName, value);
            }
        );
    };

    useEffect(() => {
        console.log("use effect", { details });
        // setAddressDetails(details)
        setInitialFormValues();
    }, [details]);

    const setOwnAddress = () => {
        setIsFormAutoFilled(false);
        //reset unvisible fields - set this manually in DB!
        [
            "formattedAddress",
            "region",
            "subregion",
            "latitude",
            "longitude",
        ].map(key => {
            const inputName = `companyAddress.${key}`;
            setValue(inputName, undefined);
        });
        setValue("companyAddress.country", "POL");
    };

    return <>
        <div className={classnames(styles.formRow, styles.moreInputs)}>
            {
                Object.keys(detailsInputs).map(
                    key => <div
                        key={key}
                    >
                        <Input
                            name={`companyAddress.${key}`}
                            disabled={isFormAutoFilled}
                        />
                    </div>
                )
            }
        </div>
        {
            !isEmpty(details) && <div className={styles.textRow}>
                {t("common:wrongAddress")}
                <Button
                    type="button"
                    kind='link'
                    onClick={() => setOwnAddress()}>
                    {t("common:btn.change")}
                </Button>
            </div>
        }
        {
            isMapLocalization && <div
                className={styles.mapWrapper}>
                <MapComponent
                    zoom={8}
                    updateOnChange={true}
                    markerPosition={
                        { y: latitude, x: longitude }
                    } />
            </div>
        }

    </>;
};

export default AddressFormDetails;