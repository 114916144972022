import { useState, useEffect } from "react";
import { CarsAndCostsT } from "@/types";
import { fetchCarById } from "@/utils/getters";

type Props = {
    carId?: string | string[] | number,
    withOwner?: boolean
}

type ReturnP = {
    car?: CarsAndCostsT,
    loading: boolean,
    error: boolean
}
function useCar({ carId, withOwner = true }: Props): ReturnP {
    const [car, setCar] = useState<CarsAndCostsT>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);

    const fetchCar = async () => {
        if (carId) {
            try {
                const fetchedCar = await fetchCarById(carId, withOwner);

                setCar(fetchedCar);
            } catch (e) {
                console.error(`Fetch car ${carId}`, e);
                setError(true);
            } finally {
                setLoading(false);
            }
        } else {
            console.error("Car id is undefined");

            setLoading(false);
            setError(true);
        }
    };

    useEffect(() => {
        fetchCar();
    }, []);

    return {
        car,
        loading,
        error
    };
}

export default useCar;