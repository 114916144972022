import { AuthContext } from "@/context/authContext";
import axios from "axios";
import { get, isString } from "lodash";
import { useContext } from "react";

type SendProps = {
    owner?: string,
    msg?: string | object
}

const useSlack = (
    source: string
) => {
    const authContext = useContext(AuthContext);

    const getOwner = (owner: string | undefined) => {
        const loggedUserEmail = get(authContext, "attrInfo", []).find(({ Name }: { Name: string }) => Name === "email")?.Value;

        if (loggedUserEmail && owner && (loggedUserEmail !== owner)) {
            return `${loggedUserEmail} [${owner}]`;
        }

        return loggedUserEmail ? loggedUserEmail : owner;
    };

    const send = ({ owner, msg = "" }: SendProps) => {
        try {
            const message = isString(msg) ? msg : JSON.stringify(msg, null, 2);
            const email = getOwner(owner);

            axios.post("/api/slack", {
                source,
                email,
                message
            });
        } catch (e) {
            console.error("Slack error", e);
        }
    };

    return {
        send
    };
};

export default useSlack;