import { getCarSlug } from "../getters";

const carParser = (carData: unknown) => {
    const {
        //@ts-ignore
        car = {},
        //@ts-ignore
        costs = {}
    } = carData;
    const url = getCarSlug(car);

    return {
        car: {
            url: `/car/${url}`,
            baseUrl: url,
            ...car,
        },
        costs
    };
};

export default carParser;