import { BrandT, CarI, CarParametersI } from "@/types";
import { isEmpty, isNumber } from "lodash";

export const getFullCarName = (car: CarI): string => {
    const {
        brand: {
            name: brandName
        },
        model,
        version
    } = car;

    return [brandName, model, version].join(" ");
};


export const getBrandNameById = (brandId: number | null, brands: BrandT[] = []): string | null => {
    if (brandId !== null) {
        const brand = brands.find(({ id }) => id === brandId);

        return brand ? brand?.name : null;
    }

    return null;
};

export const getParamsList = ({
    horsePower,
    acceleration,
    vmax,
    consumption,
    transmission,
    seats,
    drive,
    fuel,
    engine,
    body,
    color
}: Partial<CarParametersI>) => {
    const paramsToList = [
        { horsePower },
        { acceleration },
        { vmax },
        { consumption },
        { transmission },
        { seats },
        { drive },
        { fuel },
        { engine },
        { body },
        { color }
    ].filter(item => {
        const value = Object.values(item)[0];

        return isNumber(value) || !isEmpty(value);
    });

    return paramsToList;
};