import { useState, useEffect, useContext } from "react";
import { ProfileI } from "@/types";
import { serverApi } from "@/utils/api";
import config from "@/config";
import { AuthContext } from "@/context/authContext";

const { urls: { user } } = config;



type ReturnP = {
    profile?: ProfileI,
    loading: boolean,
    error: boolean,
    isLogged: boolean,
}
function useProfile(): ReturnP {
    const [profile, setProfile] = useState<ProfileI>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const authContext = useContext(AuthContext);

    const {
        isLogged,
    } = authContext;

    const fetchProfile = async () => {
        if (isLogged()) {
            try {
                const {
                    data: {
                        profile: profileData = {}
                    } = {}
                } = await serverApi.get(user?.getProfile);

                setProfile(profileData);
            } catch (e) {
                console.error("Fetch profile failed", e);
                setError(true);
            } finally {
                setLoading(false);
            }
        } else {
            console.error("User not logged");

            setLoading(false);
            setError(true);
        }
    };

    useEffect(() => {
        fetchProfile();
    }, []);

    return {
        profile,
        loading,
        error,
        isLogged: isLogged()
    };
}

export default useProfile;