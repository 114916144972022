import { ContactT } from "src/types/Company";
import {
    BsFillTelephoneFill,
    BsFillEnvelopeFill,
    BsGlobe
} from "react-icons/bs";
import Socials from "./Socials";
import { useState } from "react";
import { isEmpty, noop } from "lodash";

import styles from "./styles/Contact.module.scss";
import { getPhoneNumber, parseUrlToDisplay } from "@/utils/getters";
import useTranslation from "next-translate/useTranslation";

type Props = {
    contact: ContactT,
    show?: "all" | "socials" | "contact",
    type?: "ICON" | "LIST"
}


type IconsKeys = "phone" | "email" | "url";
type IconsT = {
    [key in IconsKeys]: any
}
const Icons: IconsT = {
    phone: BsFillTelephoneFill,
    email: BsFillEnvelopeFill,
    url: BsGlobe,
};

type ContactRowComponentProps = {
    name: IconsKeys,
    value: string,
    setOpenTab: any,
    openTab: string
}
const ContactRowComponent = ({
    name,
    value = "",
    setOpenTab = noop,
    openTab = ""
}: ContactRowComponentProps) => {
    const isShow = openTab === name;

    if (value) {
        const Icon = Icons[name];

        return <div className={styles.contactRow}
        >
            <Icon size={18}
                onClick={() => setOpenTab(name !== openTab ? name : "")}
            /> {
                isShow &&
                <div className={styles.tooltip}>
                    {name !== "url" ? value : <a
                        target="_blank"
                        rel="noreferrer"
                        href={value}>{value}</a>}
                    {/* todo zamykanie tooltipa na guziku? */}
                </div>
            }
        </div>;
    }

    return <></>;
};

const Contact = ({
    contact = {
        websiteUrl: "",
        phone: "",
        email: "",
        urls: []
    },
    show = "all",
    type = "ICON"
}: Props) => {
    const {
        email,
        phone,
        websiteUrl,
        urls
    } = contact;
    const [openTab, setOpenTab] = useState<string>("");

    if (type === "LIST") return <ContactList contact={contact} />;

    return <div className={styles.contactWrapper}>
        {
            (show === "all" || show === "contact")
            && (email || phone || websiteUrl) &&
            <div className={styles.directContact}>
                <ContactRowComponent
                    name="phone"
                    value={phone}
                    openTab={openTab}
                    setOpenTab={setOpenTab}
                />
                <ContactRowComponent
                    name="email"
                    value={email}
                    openTab={openTab}
                    setOpenTab={setOpenTab}
                />
                <ContactRowComponent
                    name="url"
                    value={parseUrlToDisplay(websiteUrl)}
                    openTab={openTab}
                    setOpenTab={setOpenTab}
                />
            </div>}
        {(show === "all" || show === "socials") && !isEmpty(urls) && <Socials list={urls} />}
    </div>;
};

type ContactListProps = {
    contact: ContactT,
}

const ContactList = ({ contact }: ContactListProps) => {
    const { t } = useTranslation();
    const {
        email,
        phone,
        websiteUrl,
    } = contact;

    return <div className={styles.contactList}>
        <div className={styles.title}>{t("common:contact")}</div>
        <ContactRow name="email" value={email} />
        <ContactRow name="phone" value={phone} />
        <ContactRow name="url" value={websiteUrl} />
    </div>;
};

const ContactRow = ({
    name, value
}: {
    name: IconsKeys,
    value: string
}) => {
    if (!value) return <></>;
    let text = value;
    const Icon = Icons[name];

    switch (name) {
    case "phone":
        text = getPhoneNumber(value, true);
        break;
    case "url":
        text = parseUrlToDisplay(value);
        break;
    default:
        text = value;
        break;
    }

    return <div className={styles.item}>
        <Icon size={16} />
        {name !== "url" ? text : <a
            target="_blank"
            rel="noreferrer"
            href={`https://${text}`}>{text}</a>}
    </div>;
};

export default Contact;