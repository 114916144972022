import { useContext, useEffect, useState } from "react";
import useTranslation from "next-translate/useTranslation";
import { useForm, FormProvider } from "react-hook-form";
import { isEmpty, pickBy } from "lodash";
import { GoLocation } from "react-icons/go";
import { FaCar } from "react-icons/fa";
import { DataContext } from "@/context/dataContext";


import { Button, Select, SelectLocation } from "./atoms";
import { getSearchUrl } from "@/getters";
import router from "next/router";
import { BrandT } from "@/types";
import { SearchBoxAdvantages } from "../Layout";

import styles from "./atoms/styles/SearchForm.module.scss";
import { useSlack } from "@/hooks/index";

const INPUT_NAMES = {
    BRAND: "brand",
    MODEL: "model",
    CITY: "city"
};


const SearchForm = () => {
    const methods = useForm();
    const { handleSubmit } = methods; //, watch, setValue, reset
    const { t } = useTranslation();
    const { send } = useSlack("Search");

    const { getBrands } = useContext(DataContext);
    const [brands, setBrands] = useState<BrandT[] | []>([]);

    // const watchBrand = watch(INPUT_NAMES.BRAND, {});
    const setBrandList = async () => {
        const list = await getBrands();
        setBrands(list);
    };

    useEffect(() => {
        setBrandList();
    }, []);

    const onSubmit = (data: any) => {
        const {
            brand: {
                value: brandValue = "",
                label: labelValue = "",
            } = {},
            model: {
                value: modelValue = "",
            } = {},
            city
        } = data;

        const searchObj = pickBy({
            brand: labelValue || brandValue,
            model: modelValue,
            city,
        }, v => v !== "");

        if (isEmpty(searchObj)) return;

        const url = getSearchUrl(searchObj);
        send({ msg: searchObj });

        router.push(url);
    };


    return <div>
        <FormProvider {...methods}>
            <form
                className={styles.formWrapper}
                onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.searchForm}>
                    <Select
                        name={INPUT_NAMES.BRAND}
                        withLabel={false}
                        Icon={<div className={styles.selectIcon}>
                            <FaCar />
                        </div>
                        }
                        options={brands.map(brand => ({
                            label: brand?.name,
                            value: brand?.id
                        }))}
                    />
                    <Select
                        name={INPUT_NAMES.MODEL}
                        withLabel={false}
                        options={[]}
                        isCreatable={true}
                        Icon={<div className={styles.selectIcon}>
                            <FaCar />
                        </div>
                        }
                    />
                    <SelectLocation
                        name={INPUT_NAMES.CITY}
                        withLabel={false}
                        placeholder={t("forms:placeholder.city")}
                        Icon={<div className={styles.selectIcon}>
                            <GoLocation />
                        </div>}
                    />
                    <Button color='blue'>
                        {
                            t("common:btn.search")
                        }
                    </Button>
                </div>
                <SearchBoxAdvantages />
            </form>
        </FormProvider>
    </div>;
};

export default SearchForm;
